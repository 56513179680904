import { useState, useEffect } from "react";
import Calendar from "../components/Calendar";
import { Image } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { fetchStartingBusiness } from "../http/resourcesServicesAPI";
import parse from "html-react-parser";

const StartingBusiness = () => {
	const [business, setBusiness] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchStartingBusiness()
			.then((data) => {
				setBusiness(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<div className="d-flex flex-md-row flex-column justify-content-center px-4">
				<div className="d-flex flex-column col-md-8 px-4">
					<div className="py-4 block-title text-center">
						<span className="text-primary">Inițierea Afacerii</span>
					</div>
					{business.count > 0
						? business.rows.map((item, idx) => (
								<div className="d-flex flex-xl-row flex-column justify-content-center">
									<Image src={process.env.REACT_APP_API_URL + item.img} />
									{item.description ? (
										<span className="text-wrap">{parse(item.description)}</span>
									) : null}
								</div>
						  ))
						: null}
					{business.count > 0 ? (
						<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 px-5">
							(
							<span className="border border-primary rounded text-primary my-sm-2 p-2 fw-bold">
								{business.rows[business.rows.length - 1].author}
							</span>
							<span className="border border-primary rounded text-primary p-2 fw-bold">
								{business.rows[business.rows.length - 1].date}
							</span>
							)
							<div className="d-flex flex-row align-items-center justify-content-end ps-1 pe-5">
								<i
									className="bi bi-printer text-primary icon-3"
									style={{
										fontWeight: "600 !important",
										WebkitTextStroke: 1,
									}}
								/>
								<div
									className="d-flex rounded ms-3 px-4 bg-primary text-white align-items-center"
									style={{ width: 146 }}
								>
									<i className="bi bi-share icon-2" />
									<span className="ps-4 font-1 fw-bold">Share</span>
								</div>
							</div>
						</div>
					) : null}
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default StartingBusiness;
