import { useState, useEffect } from "react";
import Calendar from "../components/Calendar";
import { Table } from "react-bootstrap";
import { fetchNGovOrganizations } from "../http/navLinksAPI";
import { Spinner } from "react-bootstrap";

const NGovOrganizations = () => {
	const [nGovOrganizations, setNGovOrganizations] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchNGovOrganizations()
			.then((data) => {
				setNGovOrganizations(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className="d-flex flex-md-row flex-column justify-content-center px-4">
				<div className="d-flex flex-column col-md-8 px-4">
					<div className="py-4 block-title text-center">
						<span className="text-primary">Organizații neguvernamentale</span>
					</div>
					<Table bordered hover>
						<thead>
							<tr>
								<th className="bg-primary text-white">#</th>
								<th className="bg-primary text-white">Denumire</th>
								<th className="bg-primary text-white">Adresa</th>
								<th className="bg-primary text-white">Persoana de contact</th>
								<th className="bg-primary text-white">Numar de telefon</th>
							</tr>
						</thead>
						<tbody>
							{nGovOrganizations.count > 0
								? nGovOrganizations.rows.map((item, idx) => (
										<tr key={idx}>
											<td>{item.name}</td>
											<td>{item.full_name}</td>
											<td>{item.contact_person}</td>
											<td>{item.phone}</td>
										</tr>
								  ))
								: null}
						</tbody>
					</Table>
					<div className="d-flex flex-xl-row flex-column align-items-center justify-content-end w-100">
						<i
							className="bi bi-printer text-primary icon-3 px-4"
							style={{
								fontWeight: "600 !important",
								WebkitTextStroke: 1,
							}}
						/>
						<div
							className="d-flex rounded ms-3 px-4 bg-primary text-white align-items-center"
							style={{ width: 146 }}
						>
							<i className="bi bi-share icon-2" />
							<span className="ps-4 font-1 fw-bold">Share</span>
						</div>
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default NGovOrganizations;
