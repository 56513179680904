import { makeAutoObservable } from "mobx";

export default class UsefulStore {
  constructor() {
    this._links = [
      // [
      // 	{
      // 		id: 1,
      // 		img: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Seal_of_the_Government_of_Moldova_2021.png",
      // 		title: "Viceprim-ministru pentru Reintegrare",
      // 		link: "https://facebook.com/",
      // 	},
      // 	{
      // 		id: 2,
      // 		img: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Seal_of_the_Government_of_Moldova_2021.png",
      // 		title: "Viceprim-ministru pentru Reintegrare",
      // 		link: "https://facebook.com/",
      // 	},
      // 	{
      // 		id: 3,
      // 		img: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Seal_of_the_Government_of_Moldova_2021.png",
      // 		title: "Viceprim-ministru pentru Reintegrare",
      // 		link: "https://facebook.com/",
      // 	},
      // 	{
      // 		id: 4,
      // 		img: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Seal_of_the_Government_of_Moldova_2021.png",
      // 		title: "Viceprim-ministru pentru Reintegrare",
      // 		link: "https://facebook.com/",
      // 	},
      // ],
      // [
      // 	{
      // 		id: 4,
      // 		img: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Seal_of_the_Government_of_Moldova_2021.png",
      // 		title: "Viceprim-ministru pentru Reintegrare",
      // 		link: "https://facebook.com/",
      // 	},
      // 	{
      // 		id: 4,
      // 		img: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Seal_of_the_Government_of_Moldova_2021.png",
      // 		title: "Viceprim-ministru pentru Reintegrare",
      // 		link: "https://facebook.com/",
      // 	},
      // 	{
      // 		id: 4,
      // 		img: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Seal_of_the_Government_of_Moldova_2021.png",
      // 		title: "Viceprim-ministru pentru Reintegrare",
      // 		link: "https://facebook.com/",
      // 	},
      // 	{
      // 		id: 4,
      // 		img: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Seal_of_the_Government_of_Moldova_2021.png",
      // 		title: "Viceprim-ministru pentru Reintegrare",
      // 		link: "https://facebook.com/",
      // 	},
      // ],
      // [
      // 	{
      // 		id: 4,
      // 		img: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Seal_of_the_Government_of_Moldova_2021.png",
      // 		title: "Viceprim-ministru pentru Reintegrare",
      // 		link: "https://facebook.com/",
      // 	},
      // 	{
      // 		id: 4,
      // 		img: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Seal_of_the_Government_of_Moldova_2021.png",
      // 		title: "Viceprim-ministru pentru Reintegrare",
      // 		link: "https://facebook.com/",
      // 	},
      // 	{
      // 		id: 4,
      // 		img: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Seal_of_the_Government_of_Moldova_2021.png",
      // 		title: "Viceprim-ministru pentru Reintegrare",
      // 		link: "https://facebook.com/",
      // 	},
      // ],
    ];
    makeAutoObservable(this);
  }

  setLinks(links) {
    this._links = links;
  }

  get links() {
    return this._links;
  }
}
