import React, { useContext, useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Context } from "..";
import Calendar from "../components/Calendar";
import { fetchAcquisition, fetchTenders, fetchVacancies } from "../http/informationProcessAPI";
import { Spinner, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ACQUISITION_ROUTE, TENDERS_ROUTE, VACANCIES_ROUTE } from "../utils/consts";

const PublicInfo = () => {
	const { informationProcess } = useContext(Context);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchTenders().then((data) => {
			informationProcess.setTenders(data);
		});
		fetchAcquisition().then((data) => {
			informationProcess.setAcquisition(data);
		});
		fetchVacancies()
			.then((data) => {
				informationProcess.setVacancies(data);
			})
			.finally(() => setLoading(false));
	}, [informationProcess]);

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div className="d-flex flex-md-row flex-column justify-content-around px-4 border-bottom">
			<div className="d-flex flex-column px-5 py-4 col-md-8">
				<div className="block-title pb-4">
					<span>Informații publice</span>
				</div>
				<Tabs defaultActiveKey="tenders" id="uncontrolled-tab-example" className="mb-3 text-primary fw-bold">
					<Tab eventKey="tenders" title="Licitaţii şi achiziţii">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{informationProcess.tenders.count > 0
									? informationProcess.tenders.rows.map((item, idx) => (
											<Accordion.Item
												eventKey={"tender-" + idx}
												key={"tender-" + idx}
												className="border-0"
											>
												<Accordion.Header>
													<Link
														className="fw-bold font-1 text-primary text-justify pe-4 text-decoration-none"
														to={TENDERS_ROUTE + "/" + item.id}
													>
														{item.title}
													</Link>
												</Accordion.Header>
												<div className="d-flex justify-content-start py-2 ps-4">
													<span className="border rounded border-primary px-2 text-primary">
														{item.date}
													</span>
												</div>
												<Accordion.Body>
													{item.tenders_files.length > 0
														? item.tenders_files.map((sub_item, sub_idx) => (
																<Link
																	to={process.env.REACT_APP_API_URL + sub_item.doc}
																	key={sub_idx}
																	className="text-primary text-decoration-none"
																>
																	<div className="d-flex align-items-center">
																		<i
																			className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																		/>
																		<span
																			className="text-wrap"
																			style={{ textAlign: "justify" }}
																		>
																			{sub_item.doc.split(".")[0]}
																		</span>
																	</div>
																</Link>
														  ))
														: null}
												</Accordion.Body>
											</Accordion.Item>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="acquisition" title="Planuri de achiziții">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{informationProcess.acquisition.count > 0
									? informationProcess.acquisition.rows.map((item, idx) => (
											<Accordion.Item
												eventKey={"acquisition-" + idx}
												key={"acquisition-" + idx}
												className="border-0"
											>
												<Accordion.Header>
													<Link
														className="fw-bold font-1 text-primary text-justify pe-4 text-decoration-none"
														to={ACQUISITION_ROUTE + "/" + item.id}
													>
														{item.title}
													</Link>
												</Accordion.Header>
												<div className="d-flex justify-content-start py-2 ps-4">
													<span className="border rounded border-primary px-2 text-primary">
														{item.date}
													</span>
												</div>
												<Accordion.Body>
													{item.acquisition_files.length > 0
														? item.acquisition_files.map((sub_item, sub_idx) => (
																<Link
																	to={process.env.REACT_APP_API_URL + sub_item.doc}
																	key={sub_idx}
																	className="text-primary text-decoration-none"
																>
																	<div className="d-flex align-items-center">
																		<i
																			className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																		/>
																		<span
																			className="text-wrap"
																			style={{ textAlign: "justify" }}
																		>
																			{sub_item.doc.split(".")[0]}
																		</span>
																	</div>
																</Link>
														  ))
														: null}
												</Accordion.Body>
											</Accordion.Item>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="vacancies" title="Posturi vacante">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{informationProcess.vacancies.count > 0
									? informationProcess.vacancies.rows.map((item, idx) => (
											<Accordion.Item
												eventKey={"vacancy-" + idx}
												key={"vacancy-" + idx}
												className="border-0"
											>
												<Accordion.Header>
													<Link
														className="fw-bold font-1 text-primary text-justify pe-4 text-decoration-none"
														to={VACANCIES_ROUTE + "/" + item.id}
													>
														{item.title}
													</Link>
												</Accordion.Header>
												<div className="d-flex justify-content-start py-2 ps-4">
													<span className="border rounded border-primary px-2 text-primary">
														{item.date}
													</span>
												</div>
												<Accordion.Body>
													{item.vacancies_files.length > 0
														? item.vacancies_files.map((sub_item, sub_idx) => (
																<Link
																	to={process.env.REACT_APP_API_URL + sub_item.doc}
																	key={sub_idx}
																	className="text-primary text-decoration-none"
																>
																	<div className="d-flex align-items-center">
																		<i
																			className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																		/>
																		<span
																			className="text-wrap"
																			style={{ textAlign: "justify" }}
																		>
																			{sub_item.doc.split(".")[0]}
																		</span>
																	</div>
																</Link>
														  ))
														: null}
												</Accordion.Body>
											</Accordion.Item>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
				</Tabs>
			</div>
			<Calendar />
		</div>
	);
};

export default PublicInfo;
