import { useContext, useState, useEffect } from "react";
import { Context } from "..";
import Calendar from "../components/Calendar";
import { Collapse } from "react-bootstrap";
import { Spinner, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { fetchCECEAnnouncements } from "../http/resourcesServicesAPI";
import { ANNOUNCEMENTS_ROUTE } from "../utils/consts";

const Announcements = () => {
	const { resourcesServices } = useContext(Context);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchCECEAnnouncements()
			.then((data) => {
				resourcesServices.setCECEAnnouncements(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className="d-flex flex-md-row flex-column justify-content-around px-4 border-bottom">
			<div className="d-flex flex-column px-5 py-4 col-md-8">
				<div className="block-title pb-4">
					<span>Anunțuri</span>
				</div>
				<div className="d-flex flex-column align-items-center">
					<Accordion>
						{resourcesServices.cece_announcements.count > 0
							? resourcesServices.cece_announcements.rows.map((item, idx) => (
									<Accordion.Item eventKey={idx}>
										<Accordion.Header className="text-justify">
											<span
												className="fw-bold font-1 text-primary"
												role="button"
												onClick={() => navigate(ANNOUNCEMENTS_ROUTE + "/" + item.id)}
											>
												{item.title}
											</span>
										</Accordion.Header>
										<Accordion.Body>
											{item.cece_announcements_files.length > 0
												? item.cece_announcements_files.map((sub_item, sub_idx) => (
														<a
															href="#"
															key={sub_idx}
															className="text-primary text-decoration-none"
														>
															<div className="d-flex align-items-center">
																<i
																	className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																/>
																<span
																	className="text-wrap"
																	style={{ textAlign: "justify" }}
																>
																	{sub_item.doc.split(".")[0]}
																</span>
															</div>
														</a>
												  ))
												: null}
										</Accordion.Body>
										{/* <div
                      key={idx}
                      className="d-flex flex-column justify-content-between border-bottom py-3 col-12 text-justify"
                      aria-controls={"collapse-item"}
                      aria-expanded={item.isActive}
                    >
                      <div
                        key={idx}
                        className="d-flex flex-row align-items-center"
                      >
                        <i className="bi bi-file-text icon-5 pe-4 text-primary" />
                        <div className="d-flex flex-column">
                          
                          <span
                            className="text-wrap"
                            style={{ textAlign: "justify" }}
                          >
                            {item.article}
                          </span>
                        </div>
                        {item.isActive ? (
                          <i
                            onClick={() => {
                              item.isActive = !item.isActive;
                              setState(!state);
                            }}
                            className="bi bi-chevron-up icon-2 ps-4 text-primary"
                            role="button"
                          />
                        ) : (
                          <i
                            onClick={() => {
                              item.isActive = !item.isActive;
                              setState(!state);
                            }}
                            className="bi bi-chevron-down icon-2 ps-4 text-primary"
                            role="button"
                          />
                        )}
                      </div>
                      <div className="d-flex justify-content-start align-items-center text-center py-2">
                        <span className="border rounded border-primary px-2 text-primary">
                          {item.date}
                        </span>
                      </div>
                      <Collapse in={item.isActive}>
                        <div id={"collapse-item"}>
                          
                        </div>
                      </Collapse>
                    </div> */}
									</Accordion.Item>
							  ))
							: null}
					</Accordion>
				</div>
			</div>
			<Calendar />
		</div>
	);
};

export default Announcements;
