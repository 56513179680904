import React, { useContext, useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import Calendar from "./Calendar";
import { useNavigate } from "react-router-dom";
import { CITY_NEWS_ROUTE, POLICIES_STRATEGIES_ROUTE } from "../utils/consts";
import { fetchPoliciesStrategies } from "../http/resourcesServicesAPI";
import { Spinner } from "react-bootstrap";

const PoliciesStrategies = observer(() => {
	const navigate = useNavigate();
	const { resourcesServices } = useContext(Context);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchPoliciesStrategies()
			.then((data) => {
				resourcesServices.setPoliciesStrategies(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className="d-flex flex-md-row flex-column justify-content-center px-4 border-bottom">
			<div className="px-5 col-xl-9 py-4">
				<div className="block-title">
					<span>Politici și strategii</span>
				</div>
				<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
					{resourcesServices.policiesStrategies.rows.map((item, idx) => {
						return (
							<Card
								key={idx}
								style={{ width: "22rem" }}
								className="my-3"
								onClick={() => navigate(POLICIES_STRATEGIES_ROUTE + "/" + item.id)}
							>
								{item.img ? (
									<Card.Img variant="top" src={process.env.REACT_APP_API_URL + item.img} />
								) : null}
								<Card.Body>
									<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
										<span
											className="border border-primary rounded text-primary p-2"
											style={{ fontSize: "1rem" }}
										>
											{item.date}
										</span>
									</Card.Title>
									<Card.Text
										role="button"
										onClick={() => navigate(POLICIES_STRATEGIES_ROUTE + "/" + item.id)}
									>
										{item.title}
									</Card.Text>
								</Card.Body>
							</Card>
						);
					})}
				</div>

				<div className="map d-flex flex-column py-4">
					<div className="block-title">
						<span>Urmăriți-ne pe</span>
					</div>
					<div className="d-flex flex-xl-row flex-column justify-content-between">
						<div className="d-flex flex-xl-row flex-column justify-content-around py-3">
							<a
								href="#"
								className="d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none "
							>
								<i className="bi bi-youtube pe-3 icon-3 text-primary" />
								<span className="text-primary font-2">YOUTUBE</span>
							</a>
							<a
								href="#"
								className="d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none "
							>
								<i className="bi bi-facebook pe-3 icon-3 text-primary" />
								<span className="text-primary font-2">FACEBOOK</span>
							</a>
							<a
								href="#"
								className="d-flex flex-row align-items-center justify-content-between py-2 px-3 text-decoration-none "
							>
								<i className="bi bi-telegram pe-3 icon-3 text-primary" />
								<span className="text-primary font-2">TELEGRAM</span>
							</a>
						</div>
						<div className="d-flex flex-row align-items-center justify-content-end ps-1 pe-5">
							<i
								className="bi bi-printer text-primary icon-3"
								style={{
									fontWeight: "600 !important",
									WebkitTextStroke: 1,
								}}
							/>
							<div
								className="d-flex rounded ms-3 px-4 bg-primary text-white align-items-center"
								style={{ width: 146 }}
							>
								<i className="bi bi-share icon-2" />
								<span className="ps-4 font-1 fw-bold">Share</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Calendar />
		</div>
	);
});

export default PoliciesStrategies;
