import { makeAutoObservable } from "mobx";

export default class EconomyStore {
  constructor() {
    this._indicators = [
      // {
      //   id: 1,
      //   title:
      //     "Regulamentul privind 11constituirea și funcționarea Consiliului raional Edineț",
      //   active: false,
      //   date: "18.05.2024 11:34",
      //   files: [
      //     {
      //       id: 1,
      //       doc_name: "Test Doc 1",
      //       doc_type: "pdf",
      //     },
      //     {
      //       id: 2,
      //       doc_name: "Test Doc 2",
      //       doc_type: "docx",
      //     },
      //   ],
      // },
      // {
      //   id: 2,
      //   title:
      //     "Regulile de întocmire şi cerinţele de informare, consultare şi participare în procesul de elaborare şi adoptare a deciziilor în cadrul Consiliului raional Edineț",
      //   active: false,
      //   date: "18.05.2024 11:34",
      //   files: [
      //     {
      //       id: 1,
      //       doc_name: "Test Doc 1",
      //       doc_type: "pdf",
      //     },
      //     {
      //       id: 2,
      //       doc_name: "Test Doc 2",
      //       doc_type: "docx",
      //     },
      //   ],
      // },
    ];
    this._budget = [
      // {
      //   id: 1,
      //   title:
      //     "ARegulamentul privind constituirea și funcționarea Consiliului raional Edineț",
      //   active: false,
      //   date: "18.05.2024 11:34",
      //   files: [
      //     {
      //       id: 1,
      //       doc_name: "Test Doc 1",
      //       doc_type: "pdf",
      //     },
      //     {
      //       id: 2,
      //       doc_name: "Test Doc 2",
      //       doc_type: "docx",
      //     },
      //   ],
      // },
      // {
      //   id: 2,
      //   title:
      //     "Regulile de întocmire şi cerinţele de informare, consultare şi participare în procesul de elaborare şi adoptare a deciziilor în cadrul Consiliului raional Edineț",
      //   active: false,
      //   date: "18.05.2024 11:34",
      //   files: [
      //     {
      //       id: 1,
      //       doc_name: "Test Doc 1",
      //       doc_type: "pdf",
      //     },
      //     {
      //       id: 2,
      //       doc_name: "Test Doc 2",
      //       doc_type: "docx",
      //     },
      //   ],
      // },
    ];
    this._publicProperty = [
      // {
      //   id: 1,
      //   title:
      //     "ARegulamentul privind constituirea și funcționarea Consiliului raional Edineț",
      //   active: false,
      //   date: "18.05.2024 11:34",
      //   files: [
      //     {
      //       id: 1,
      //       doc_name: "Test Doc 1",
      //       doc_type: "pdf",
      //     },
      //     {
      //       id: 2,
      //       doc_name: "Test Doc 2",
      //       doc_type: "docx",
      //     },
      //   ],
      // },
      // {
      //   id: 2,
      //   title:
      //     "BRegulile de întocmire şi cerinţele de informare, consultare şi participare în procesul de elaborare şi adoptare a deciziilor în cadrul Consiliului raional Edineț",
      //   active: false,
      //   date: "18.05.2024 11:34",
      //   files: [
      //     {
      //       id: 1,
      //       doc_name: "Test Doc 1",
      //       doc_type: "pdf",
      //     },
      //     {
      //       id: 2,
      //       doc_name: "Test Doc 2",
      //       doc_type: "docx",
      //     },
      //   ],
      // },
    ];
    this._tourismMarketing = [
      // {
      //   id: 1,
      //   title:
      //     "ARegulamentul privind constituirea și funcționarea Consiliului raional Edineț",
      //   active: false,
      //   date: "18.05.2024 11:34",
      //   files: [
      //     {
      //       id: 1,
      //       doc_name: "Test Doc 1",
      //       doc_type: "pdf",
      //     },
      //     {
      //       id: 2,
      //       doc_name: "Test Doc 2",
      //       doc_type: "docx",
      //     },
      //   ],
      // },
      // {
      //   id: 2,
      //   title:
      //     "BRegulile de întocmire şi cerinţele de informare, consultare şi participare în procesul de elaborare şi adoptare a deciziilor în cadrul Consiliului raional Edineț",
      //   active: false,
      //   date: "18.05.2024 11:34",
      //   files: [
      //     {
      //       id: 1,
      //       doc_name: "Test Doc 1",
      //       doc_type: "pdf",
      //     },
      //     {
      //       id: 2,
      //       doc_name: "Test Doc 2",
      //       doc_type: "docx",
      //     },
      //   ],
      // },
    ];
    makeAutoObservable(this);
  }

  setIndicators(indicators) {
    this._indicators = indicators;
  }
  get indicators() {
    return this._indicators;
  }

  setBudget(budget) {
    this._budget = budget;
  }
  get budget() {
    return this._budget;
  }

  setPublicProperty(publicProperty) {
    this._publicProperty = publicProperty;
  }
  get publicProperty() {
    return this._publicProperty;
  }

  setTourismMarketing(tourismMarketing) {
    this._tourismMarketing = tourismMarketing;
  }

  get tourismMarketing() {
    return this._tourismMarketing;
  }
}
