import React, { useState, useEffect } from "react";
import Calendar from "../components/Calendar";
import { Image } from "react-bootstrap";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { fetchOneCalendarEvents } from "../http/calendarAPI";
import parse from "html-react-parser";

const EventPage = observer(() => {
	const [event, setEvent] = useState([]);
	const { id } = useParams();

	useEffect(() => {
		fetchOneCalendarEvents(id).then((data) => setEvent(data));
	}, [id]);

	return (
		<div>
			<div className="d-flex flex-md-row flex-column justify-content-center px-4">
				<div className="d-flex flex-column col-md-8 px-4">
					<div className="py-4 block-title text-center">
						<span className="text-primary px-2">{event.title}</span>
					</div>
					<div className="d-flex flex-xl-row flex-column align-items-center justify-content-center">
						<Image
							style={{ maxHeight: 400 }}
							className="rounded"
							src={process.env.REACT_APP_API_URL + event.img}
						/>
						{event.description ? (
							<span className="text-wrap font-1 text-primary ps-xl-3">{parse(event.description)}</span>
						) : null}
					</div>

					<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between w-100">
						<span className="border border-primary rounded text-primary my-sm-2 p-2 fw-bold">
							Organizator: {event.organizer}
						</span>
						<span className="border border-primary rounded text-primary p-2 fw-bold">
							{event.date + " " + event.time}
						</span>
						<div className="d-flex flex-row align-items-center justify-content-end ps-xl-1">
							{/* <i
								className="bi bi-printer text-primary icon-3"
								style={{
									fontWeight: "600 !important",
									WebkitTextStroke: 1,
								}}
							/> */}
							<div
								className="d-flex rounded ms-xl-3 my-2 px-4 bg-primary text-white align-items-center"
								style={{ width: 146 }}
							>
								<i className="bi bi-share icon-2" />
								<span className="ps-4 font-1 fw-bold">Share</span>
							</div>
						</div>
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
});

export default EventPage;
