import { makeAutoObservable } from "mobx";

export default class ContactInformationStore {
	constructor() {
		this._contactInformation = {
			// page_title: "Pagina oficială a Consiliului Raional Edineț",
			// org_logo: "",
			// org_region: "Edineț",
			// org_name: "Consiliul Raional",
			// org_phone: "0 (246) 2-20-58",
			// org_email: "consiliul.raional-municipiul-edinet@apl.gov.md",
			// org_address:
			//   "Strada Independenței 33, MD-4601, Edineţmun. Ungheni, Bulevardul Ştefan cel Mare şi Sfânt, 83",
			// org_schedule: "Luni - Vineri, 08:00 - 17:00",
			// org_copyright: "Consiliului Raional Edineț",
			// org_updated: "Duminică, 28 Ianuarie 2024",
			// org_redline_phone: "+373 69 99 90 21",
			// org_redline_email: "antitrafic@procuratura.md",
			// facebook_link: "https://www.facebook.com/consiliulraional.edinet",
			// telegram_link: "",
			// youtube_link: "",
		};
		makeAutoObservable(this);
	}

	setContactInformation(contactInformation) {
		this._contactInformation = contactInformation;
	}

	get contactInformation() {
		return this._contactInformation;
	}
}
