import { useState, useContext } from "react";
import { Form, Card, Image, Accordion, useAccordionButton } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Context } from "../..";
import { observer } from "mobx-react-lite";
import { removeOneUsefulLinks, updateOneUsefulLinks } from "../../http/usefulLinksAPI";
import AddUsefulLinkModal from "./Modals/AddUsefulLinkModal";
import { Link } from "react-router-dom";

const UsefulAdmin = observer(() => {
	const { usefulLinks } = useContext(Context);
	const [title, setTitle] = useState("");
	const [link, setLink] = useState("");
	const [loading, setLoading] = useState(true);
	const [img, setImg] = useState([null]);
	const [show, setShow] = useState(false);

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
				onClick={decoratedOnClick}
			>
				{children}
			</button>
		);
	};

	const editReference = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("link", link);
		formData.append("img", img);
		updateOneUsefulLinks(formData, id);
	};

	const removeUsefulLink = (item) => {
		removeOneUsefulLinks(item.id).then(() => window.location.reload());
	};

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	return (
		<div className="d-flex flex-column bg-white r-corner-radius w-100">
			<div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
				<span>Lincuri Utile</span>
				<button
					className="btn btn-primary r-u-corner-radius"
					style={{ height: 70, width: 70 }}
					onClick={() => setShow(true)}
				>
					<i className="bi bi-plus-circle icon-2" />
				</button>
			</div>
			<div className="d-flex flex-column px-3">
				<Accordion className="d-flex flex-column px-3">
					{usefulLinks.links.map((item, idx) =>
						item.map((sub_item, sub_idx) => (
							<div key={sub_idx} className="shadow-sm border-0 my-2 px-4">
								<div className="d-flex flex-column">
									<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
										{/* <div> */}
										<Image
											style={{ maxHeight: 100 }}
											src={process.env.REACT_APP_API_URL + sub_item.img}
											className="gov-logo-useful"
										/>
										<Link to={sub_item.link} className="text-decoration-none text-primary">
											<Card.Title className=" text-primary">{sub_item.title}</Card.Title>
										</Link>
										<Card.Text
											style={{
												textDecoration: "underline",
												color: "#0000EE",
											}}
										>
											{sub_item.link}
										</Card.Text>
										{/* </div> */}
										<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
											<CustomToggle
												eventKey={sub_idx}
												onClick={() => {
													useAccordionButton({ sub_idx });
												}}
											>
												<i className="bi bi-pencil-square icon-2 pe-2" />
												<span>Edit</span>
											</CustomToggle>
											<button
												className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
												onClick={() => removeUsefulLink(sub_item)}
											>
												<i className="bi bi-x-circle icon-2 pe-2" />
												<span>Remove</span>
											</button>
										</div>
									</div>
								</div>
								<Accordion.Collapse eventKey={sub_idx}>
									<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
										<span className="text-primary  font-2 py-2 ps-1">Denumire</span>
										<Form.Control
											aria-label="Title"
											placeholder={sub_item.title}
											value={title}
											onChange={(e) => {
												setTitle(e.target.value);
											}}
										/>
										<span className="text-primary  font-2 py-2 ps-1">Lincul catre resurs</span>
										<Form.Control
											aria-label="Link"
											placeholder={sub_item.link}
											value={link}
											onChange={(e) => {
												setLink(e.target.value);
											}}
										/>
										<span className="text-primary  font-2 py-2 ps-1">Logo-ul</span>
										{/* <InputGroup className='mb-3'>
										<InputGroup.Text id='basic-addon'>Logo-ul</InputGroup.Text> */}
										<Form.Control
											type={"file"}
											onChange={selectFile}
											placeholder={
												// "Actual: " + contactInformation.contactInformation.org_logo
												sub_item.img
											}
											aria-label="PageTitle"
											aria-describedby="basic-addon"
										/>
										{/* </InputGroup> */}
										<button
											className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
											onClick={() => editReference(sub_item)}
										>
											<i className="bi bi-check icon-2 pe-2" />
											<span>Confirm</span>
										</button>
									</div>
								</Accordion.Collapse>
							</div>
						)),
					)}
				</Accordion>
			</div>
			<AddUsefulLinkModal show={show} onHide={() => setShow(false)} />
		</div>
	);
});

export default UsefulAdmin;
