import axios from "axios";

const $host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const $cece = axios.create({
  baseURL: "https://serviciidate.cec.md",
});

const authInterceptor = (config) => {
  config.headers.authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
};

$authHost.interceptors.request.use(authInterceptor);
// $authHost.interceptors.response.use(
//   function (response) {
//     throw new axios.Cancel("Operation canceled by the user.");
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

export { $host, $authHost, $cece };
