import { useContext, useState } from "react";
import { Form, Modal, Button, Dropdown, DropdownButton } from "react-bootstrap";
import {
	createChurchesMonasteries,
	createForKids,
	createHistoricBuildings,
	createHotels,
	createMonuments,
	createMuseums,
	createNotablePersonalities,
	createParks,
	createTouristActivities,
	createTouristRoutes,
} from "../../../http/resourcesServicesAPI";
import { Context } from "../../..";

const AddVisitDistrictModal = ({ show, onHide, selectedTab }) => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [img, setImg] = useState([null]);
	const [files, setFiles] = useState([null]);
	const [author, setAuthor] = useState("");

	const addItem = () => {
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		formData.append("author", author);

		for (const file of files) {
			formData.append("files", file);
		}
		switch (selectedTab) {
			case "Trasee Turistice":
				createTouristRoutes(formData).then(() => window.location.reload());
				break;
			case "Hoteluri":
				createHotels(formData).then(() => window.location.reload());
				break;
			case "Musee":
				createMuseums(formData).then(() => window.location.reload());
				break;
			case "Parcuri":
				createParks(formData).then(() => window.location.reload());
				break;
			case "Activitati Turistice":
				createTouristActivities(formData).then(() => window.location.reload());
				break;
			case "Cladiri Istorice":
				createHistoricBuildings(formData).then(() => window.location.reload());
				break;
			case "Monumente":
				createMonuments(formData).then(() => window.location.reload());
				break;
			case "Personalitati Marcante":
				createNotablePersonalities(formData).then(() => window.location.reload());
				break;
			case "Pentru Copii":
				createForKids(formData).then(() => window.location.reload());
				break;
			case "Biserici si Manastiri":
				createChurchesMonasteries(formData).then(() => window.location.reload());
				break;
		}
	};

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	const selectMultipleFiles = (e) => {
		const files = Array.from(e.target.files);
		setFiles(files);
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			className='add-news-modal border-primary'>
			<Modal.Header
				closeButton={onHide}
				className='bg-primary'
				closeVariant='white'>
				<Modal.Title className='text-white'>{selectedTab}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='d-flex flex-column justify-content-around px-4 py-4 custom-dropdown'>
					<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
					<Form.Control
						aria-label='Title'
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
					<Form.Control
						aria-label='Title'
						value={description}
						onChange={(e) => {
							setDescription(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Autor</span>
					<Form.Control
						aria-label='Title'
						value={author}
						onChange={(e) => {
							setAuthor(e.target.value);
						}}
					/>
					<span className='text-primary font-2 py-2 ps-1'>Fotografie</span>
					<Form.Control
						type={"file"}
						onChange={selectFile}
						aria-label='PageTitle'
						aria-describedby='basic-addon'
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={onHide}>
					Close
				</Button>
				<Button
					variant='primary'
					onClick={() => {
						addItem();
						onHide();
					}}>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddVisitDistrictModal;
