import { useState, useEffect } from "react";
import Calendar from "../../components/Calendar";
import { Image } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchOneDraftDecisions } from "../../http/navLinksAPI";

const DraftDecisionsPage = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	useEffect(() => {
		fetchOneDraftDecisions(id)
			.then((data) => {
				setData(data);
			})
			.finally(() => setLoading(false));
	}, [id]);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className="d-flex flex-md-row flex-column justify-content-center px-4">
				{data ? (
					<div className="d-flex flex-column col-md-8 px-4">
						<div className="py-4 block-title text-center">
							<span className="text-primary">{data.title}</span>
						</div>
						<div className="d-flex flex-column justify-content-center pb-4">
							{data.img ? (
								<Image
									className="rounded"
									style={{
										maxHeight: "50vh",
										width: "auto",
										aspectRatio: 1,
										objectFit: "contain",
									}}
									src={process.env.REACT_APP_API_URL + data.img}
								/>
							) : null}
							<span className="text-justify font-2 pb-2">{data.description}</span>
							{data.draft_decisions_files.map((sub_item, sub_idx) => (
								<a
									href={process.env.REACT_APP_API_URL + sub_item.doc}
									target="_blank"
									download
									key={sub_idx}
									className="text-primary text-decoration-none py-2"
								>
									<div className="d-flex align-items-center">
										<i
											className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
										/>
										<span className="text-wrap font-2" style={{ textAlign: "justify" }}>
											{sub_item.doc.split(".")[0]}
										</span>
									</div>
								</a>
							))}
						</div>
						<div className="d-flex flex-md-row flex-column align-items-center justify-content-end">
							<div className="d-flex flex-row align-items-center justify-content-end">
								<i
									className="bi bi-printer text-primary icon-3"
									style={{
										fontWeight: "600 !important",
										WebkitTextStroke: 1,
									}}
								/>
								<div
									className="d-flex rounded ms-3 px-4 bg-primary text-white align-items-center"
									style={{ width: 146 }}
								>
									<i className="bi bi-share icon-2" />
									<span className="ps-4 font-1 fw-bold">Share</span>
								</div>
							</div>
						</div>
					</div>
				) : null}
				<Calendar />
			</div>
		</div>
	);
};

export default DraftDecisionsPage;
