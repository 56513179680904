import React, { useContext } from "react";
import { Image } from "react-bootstrap";
// import gov_logo from "../assets/gov_logo.png";
import dev_logo from "../assets/it-lab_logo_white.svg";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { ADMIN_ROUTE } from "../utils/consts";

const Footer = observer(() => {
	const { contactInformation } = useContext(Context);

	return (
		<>
			<footer className="d-flex flex-column flex-wrap justify-content-around align-items-center bg-primary text-light">
				{contactInformation.contactInformation ? (
					<div className="upper-level d-flex flex-row flex-wrap justify-content-around">
						<div className="d-flex flex-column align-items-center justify-content-center p-5">
							<Image
								style={{ width: 100, height: "auto" }}
								src={process.env.REACT_APP_API_URL + contactInformation.contactInformation.org_logo}
							/>
							<h4 className="py-2 font-3">{contactInformation.contactInformation.org_name}</h4>
						</div>
						<div className="footer-links d-flex flex-column justify-content-between p-5">
							<Link
								to={contactInformation.contactInformation.youtube_link}
								className="d-flex flex-row align-items-center justify-content-between py-2"
							>
								<i className="bi bi-youtube pe-3 icon-3" />
								<span className="ps-2">YOUTUBE</span>
							</Link>
							<Link
								to={contactInformation.contactInformation.facebook_link}
								className="d-flex flex-row align-items-center justify-content-between py-2"
							>
								<i className="bi bi-facebook pe-3 icon-3" />
								<span>FACEBOOK</span>
							</Link>
							<Link
								to={contactInformation.contactInformation.telegram_link}
								className="d-flex flex-row align-items-center justify-content-between py-2"
							>
								<i className="bi bi-telegram pe-3 icon-3" />
								<span>TELEGRAM</span>
							</Link>
						</div>
						<div className="footer-links d-flex flex-column justify-content-between p-5">
							<Link to={"#"} className="d-flex flex-row align-items-center justify-content-between py-2">
								<i className="bi bi-diagram-3-fill pe-4 icon-3" />
								<span className="ps-3">Harta site-ului</span>
							</Link>
							<div className="d-flex flex-row align-items-center justify-content-between py-2">
								<i className="bi bi-envelope pe-3 icon-3" />
								<span className="ps-3">
									{contactInformation.contactInformation.org_email || "Test Inforation"}
								</span>
							</div>
							<div className="d-flex flex-row align-items-center justify-content-between py-2">
								<i className="bi bi-telephone pe-3 icon-3" />
								<span className="ps-3">
									{contactInformation.contactInformation.org_phone || "Test Inforation"}
								</span>
							</div>
						</div>
						<div className="footer-links d-flex flex-column justify-content-between p-5">
							<div className="d-flex flex-column py-2">
								<div className="footer-info-header d-flex flex-row align-items-center">
									<i className="bi bi-geo-alt icon-3" />
									<span className="ps-3 fw-bold font-2">Adresa</span>
								</div>
								<div className="footer-info-footer d-flex flex-column">
									<span className="ps-3 text-wrap">
										{contactInformation.contactInformation.org_address || "Test Inforation"}
									</span>
								</div>
							</div>
							<div className="d-flex flex-column py-2">
								<div className="footer-info-header d-flex flex-row align-items-center">
									<i className="bi bi-clock icon-3" />
									<span className="ps-3 fw-bold font-2">Program de lucru:</span>
								</div>
								<div className="footer-info-footer d-flex flex-column">
									<span className="ps-3">
										{contactInformation.contactInformation.org_schedule || "Test Inforation"}
									</span>
								</div>
							</div>
						</div>
						<div className="footer-links d-flex flex-column justify-content-between p-5">
							<div className="d-flex flex-row align-items-center justify-content-between py-2">
								<i className="bi bi-newspaper icon-4" />
								<div className="d-flex flex-column">
									<div className="footer-info-header d-flex flex-row justify-content-between">
										<span className="ps-3 fw-bold font-2">Abonează-te</span>
									</div>
									<div className="footer-info-footer d-flex flex-column ">
										<div
											role="button"
											onClick={() => alert("Subscribed! DEMO")}
											className="ps-3 text-decoration-underline"
										>
											la newsletter-ul nostru
										</div>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column py-md-2 py-5">
								<div className="footer-info-header align-items-center justify-content-center d-flex flex-row ">
									<span className="ps-3 fw-bold font-2">Linia Verde Antitrafic</span>
								</div>
								<div className="footer-info-footer d-flex flex-column">
									<div className="d-flex justify-content-between align-items-center">
										<i className="bi bi-envelope pe-3 icon-2" />
										<span>
											{contactInformation.contactInformation.org_redline_email ||
												"Test Inforation"}
										</span>
									</div>
									<div className="d-flex justify-content-between align-items-center">
										<i className="bi bi-telephone pe-3 icon-2" />
										<span>
											{contactInformation.contactInformation.org_redline_phone ||
												"Test Inforation"}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
				<div className="web-site-info lower-level  col-12 justify-content-between py-2 align-items-center justify-content-center px-4 text-info">
					<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
						{contactInformation.contactInformation ? (
							<span className="my-0 font-1">{contactInformation.contactInformation.org_copyright}</span>
						) : null}
						<div className="d-flex align-items-center justify-content-center px-5">
							<span className="pe-3">Dezvoltat de IT-Lab</span>
							<Image style={{ height: 40, width: 40 }} src={dev_logo} />
						</div>
						{contactInformation.contactInformation ? (
							<Link to={ADMIN_ROUTE}>
								<span className="my-0">{contactInformation.contactInformation.org_updated}</span>
							</Link>
						) : null}
					</div>
				</div>
			</footer>
		</>
	);
});

export default Footer;
