import { useEffect, useState, useContext } from "react";
import { Form, Spinner, Accordion, Card, Image, useAccordionButton } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { fetchMainBanner, removeOneMainBanner, updateOneMainBanner } from "../../http/activitiesAPI";
import { useNavigate } from "react-router-dom";
import { MAIN_BANNER_INFO } from "../../utils/consts";
import parse from "html-react-parser";
import AddMainBannerModal from "./Modals/AddMainBannerModal";

const MainBannerAdmin = observer(() => {
	const { activities } = useContext(Context);
	const [show, setShow] = useState(false);

	const [loading, setLoading] = useState(true);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [img, setImg] = useState([null]);
	const [autor, setAutor] = useState("");

	const navigate = useNavigate();

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
				onClick={decoratedOnClick}
			>
				{children}
			</button>
		);
	};

	const editMainBanner = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		formData.append("autor", autor);
		updateOneMainBanner(formData, id);
	};

	const removeMainBanner = (item) => {
		removeOneMainBanner(item.id).then(() => window.location.reload());
	};

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	useEffect(() => {
		fetchMainBanner()
			.then((data) => {
				activities.setMainBanner(data);
			})
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className="d-flex flex-column bg-white r-corner-radius w-100">
			<div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
				<span>Panoul Principal</span>
				<button
					className="btn btn-primary r-u-corner-radius"
					style={{ height: 70, width: 70 }}
					onClick={() => setShow(true)}
				>
					<i className="bi bi-plus-circle icon-2" />
				</button>
			</div>
			<div className="d-flex flex-column px-3">
				<Accordion className="d-flex flex-column px-3">
					{activities.mainBanner.count > 0
						? activities.mainBanner.rows.map((item, idx) => (
								<div key={idx} className="shadow-sm border-0 my-2 px-4">
									<div className="d-flex flex-column">
										<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
											<div className="d-flex flex-column justify-content-center">
												<Image
													style={{
														maxHeight: 200,
														aspectRatio: 1,
														height: "auto",
														objectFit: "contain",
													}}
													src={process.env.REACT_APP_API_URL + item.img}
													className="pe-xl-5"
												/>
												<a
													role="button"
													onClick={() => navigate(MAIN_BANNER_INFO + "/" + item.id)}
													className="text-decoration-none text-primary"
												>
													<Card.Title className=" text-primary">{item.title}</Card.Title>
												</a>
												{item.description
													? parse(Array.from(item.description).splice(0, 60).join("") + "...")
													: null}
											</div>
											<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
												<CustomToggle
													eventKey={idx}
													onClick={() => {
														useAccordionButton({ idx });
													}}
												>
													<i className="bi bi-pencil-square icon-2 pe-2" />
													<span>Edit</span>
												</CustomToggle>
												<button
													className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
													onClick={() => removeMainBanner(item)}
												>
													<i className="bi bi-x-circle icon-2 pe-2" />
													<span>Remove</span>
												</button>
											</div>
										</div>
									</div>
									<Accordion.Collapse eventKey={idx}>
										<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
											<span className="text-primary  font-2 py-2 ps-1">Denumire</span>
											<Form.Control
												aria-label="Title"
												placeholder={item.title}
												value={title}
												onChange={(e) => {
													setTitle(e.target.value);
												}}
											/>
											<span className="text-primary  font-2 py-2 ps-1">Descriere</span>
											<Form.Control
												aria-label="Title"
												placeholder={item.description}
												value={title}
												onChange={(e) => {
													setTitle(e.target.value);
												}}
											/>
											<span className="text-primary  font-2 py-2 ps-1">Fotografie</span>
											<Form.Control
												type={"file"}
												onChange={selectFile}
												placeholder={
													// "Actual: " + contactInformation.contactInformation.org_logo
													item.img
												}
												aria-label="PageTitle"
												aria-describedby="basic-addon"
											/>
											<span className="text-primary  font-2 py-2 ps-1">Autor</span>
											<Form.Control
												aria-label="Title"
												placeholder={item.author}
												value={title}
												onChange={(e) => {
													setAutor(e.target.value);
												}}
											/>
											{/* </InputGroup> */}
											<button
												className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
												onClick={() => editMainBanner(item)}
											>
												<i className="bi bi-check icon-2 pe-2" />
												<span>Confirm</span>
											</button>
										</div>
									</Accordion.Collapse>
								</div>
						  ))
						: null}
				</Accordion>
			</div>
			<AddMainBannerModal show={show} onHide={() => setShow(false)} />
		</div>
	);
});

export default MainBannerAdmin;
