import React from "react";
import Calendar from "../components/Calendar";

const Stream = () => {
	return (
		<div>
			<div className='d-flex flex-md-row flex-column justify-content-center px-4'>
				<div className='d-flex flex-column '>
					<div className='py-4 block-title text-center'>
						<span className='text-primary'>Calendar activități</span>
					</div>
					<span className='text-wrap'>
						„Comerț – 2030”ANUNŢ privind organizarea consultărilor publice asupra proiectului de decizie a CMC „Cu privire la
						aprobarea Strategiei de dezvoltare a comerțului interior în municipiul Chișinău „Comerț – 2030”ANUNŢ privind
						organizarea consultărilor publice asupra proiectului de decizie a CMC „Cu privire la aprobarea Strategiei de
						dezvoltare a comerțului interior în municipiul Chișinău „Comerț – 2030”
					</span>
					<iframe
						title='stream'
						src='https://www.youtube.com/embed/ppCkVD_M5m4'
						className='w-100 p-3'
						style={{ minHeight: "70vh" }}
					/>
					<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 px-5'>
						<span className='border border-primary rounded text-primary my-sm-2 p-2 fw-bold'>
							Autor: Buga Marin, consilier raional
						</span>
						<span className='border border-primary rounded text-primary p-2 fw-bold'>18.05.2024 11:34</span>
					</div>
					<div className='d-flex flex-row align-items-center justify-content-end ps-1 pe-5'>
						<i
							className='bi bi-printer text-primary icon-3'
							style={{
								fontWeight: "600 !important",
								WebkitTextStroke: 1,
							}}
						/>
						<div
							className='d-flex rounded ms-3 px-4 bg-primary text-white align-items-center'
							style={{ width: 146 }}>
							<i className='bi bi-share icon-2' />
							<span className='ps-4 font-1 fw-bold'>Share</span>
						</div>
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default Stream;
