import { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import {
  createTenders,
  createAcquisition,
  createVacancies,
} from "../../../http/informationProcessAPI";
import {
  createApplicationForms,
  createInitiatingPublicPrivatePartnerships,
  createPoliciesStrategies,
  createPublicTransportNetwork,
} from "../../../http/resourcesServicesAPI";

const AddServiceListModal = ({ show, onHide, selectedTab }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState([null]);
  const [files, setFiles] = useState([null]);

  const addItem = () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("img", img);
    for (const file of files) {
      formData.append("files", file);
    }
    switch (selectedTab) {
      case "Formulare Cereri":
        createApplicationForms(formData);
        break;
      case "Politici si Strategii":
        createPoliciesStrategies(formData);
        break;
      case "Reteaua Transport Public":
        createPublicTransportNetwork(formData);
        break;
      case "Initiere Parteniriate Publice/Private":
        createInitiatingPublicPrivatePartnerships(formData);
        break;
    }
  };

  const selectFile = (e) => {
    setImg(e.target.files[0]);
  };

  const selectMultipleFiles = (e) => {
    const files = Array.from(e.target.files);
    setFiles(files);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="add-news-modal border-primary"
    >
      <Modal.Header
        closeButton={onHide}
        className="bg-primary"
        closeVariant="white"
      >
        <Modal.Title className="text-white">{selectedTab}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column justify-content-around px-4 py-4">
          <span className="text-primary  font-2 py-2 ps-1">Denumire</span>
          <Form.Control
            aria-label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <span className="text-primary  font-2 py-2 ps-1">Descriere</span>
          <Form.Control
            aria-label="Title"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          <span className="text-primary  font-2 py-2 ps-1">Fotografie</span>
          <Form.Control
            type={"file"}
            onChange={selectFile}
            aria-label="PageTitle"
            aria-describedby="basic-addon"
          />
          <span className="text-primary  font-2 py-2 ps-1">Documente</span>
          <Form.Control
            type={"file"}
            onChange={selectMultipleFiles}
            aria-label="PageTitle"
            aria-describedby="basic-addon"
            multiple
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            addItem();
            onHide();
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddServiceListModal;
