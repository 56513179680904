import { makeAutoObservable } from "mobx";

export default class ReferencesStore {
  constructor() {
    this._references = [
      // {
      //   id: 1,
      //   title: "Decizii CMC",
      //   link: "www.facebook.com/",
      // },
      // {
      //   id: 2,
      //   title: "Ref2",
      //   link: "www.facebook.com/",
      // },
    ];

    makeAutoObservable(this);
  }

  setReferences(references) {
    this._references = references;
  }

  get references() {
    return this._references;
  }
}
