import LastNews from "../components/LastNews";
import ResourcesServices from "../components/ResourcesServices";
import Activities from "../components/Activities";
import CityNews from "../components/CityNews";
import InformationProcess from "../components/InformationProcess";
import { useContext, useEffect, useState } from "react";
import { Context } from "..";
import { fetchOneContactInformation } from "../http/contactInformationAPI";
import { fetchUsefulLinks } from "../http/usefulLinksAPI";
import {
	fetchAcquisition,
	fetchConsultations,
	fetchInitiations,
	fetchRecommendations,
	fetchTenders,
	fetchVacancies,
} from "../http/informationProcessAPI";
import { observer } from "mobx-react-lite";
import { Spinner } from "react-bootstrap";

const Home = observer(() => {
	const { contactInformation, usefulLinks, informationProcess } = useContext(Context);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchOneContactInformation(1)
			.then((data) => {
				contactInformation.setContactInformation(data);
			})
			.finally(() => setLoading(false));

		// fetchTenders().then((data) => {
		// 	informationProcess.setTenders(data);
		// });
		// fetchAcquisition().then((data) => {
		// 	informationProcess.setAcquisition(data);
		// });
		// fetchVacancies().then((data) => {
		// 	informationProcess.setVacancies(data);
		// });

		// fetchConsultations().then((data) => {
		// 	informationProcess.setConsultation(data);
		// });
		// fetchInitiations().then((data) => {
		// 	informationProcess.setInitiation(data);
		// });
		// fetchRecommendations()
		// 	.then((data) => {
		// 		informationProcess.setRecommendations(data);
		// 	})
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<Activities />
			<LastNews />
			<ResourcesServices />
			<CityNews />
			<InformationProcess />
		</div>
	);
});

export default Home;
