import { useState, useEffect } from "react";
import Calendar from "../components/Calendar";
import { Spinner } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { fetchSpecialCommittees } from "../http/navLinksAPI";

const SpecializedCommittees = () => {
	const [committees, setCommittees] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchSpecialCommittees()
			.then((data) => {
				setCommittees(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className="d-flex flex-md-row flex-column justify-content-center px-4">
				<div className="d-flex flex-column col-md-8 px-4">
					<div className="py-4 block-title text-center">
						<span className="text-primary">
							Componenţa nominală a comisiilor de specialitate în Consiliul raional Edineț
						</span>
					</div>

					{committees.count > 0
						? committees.rows.map((item, idx) => (
								<div key={idx}>
									<div className="py-4 block-title text-center font-2 py-4">
										<span className="text-primary">{item.committeeTitle}</span>
									</div>
									<Table bordered hover>
										<thead>
											<tr>
												<th className="bg-primary text-white">#</th>
												<th className="bg-primary text-white">Numele, Prenumele</th>
												<th className="bg-primary text-white">Partidul</th>
											</tr>
										</thead>
										<tbody>
											{item.special_committees_members.map((sub_item, sub_idx) => (
												<tr key={idx}>
													<td>{sub_item.idx}</td>
													<td>{sub_item.full_name}</td>
													<td>{sub_item.party_name}</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
						  ))
						: null}

					<div className="d-flex flex-xl-row flex-column align-items-center justify-content-end w-100">
						<i
							className="bi bi-printer text-primary icon-3 px-4"
							style={{
								fontWeight: "600 !important",
								WebkitTextStroke: 1,
							}}
						/>
						<div
							className="d-flex rounded ms-3 px-4 bg-primary text-white align-items-center"
							style={{ width: 146 }}
						>
							<i className="bi bi-share icon-2" />
							<span className="ps-4 font-1 fw-bold">Share</span>
						</div>
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default SpecializedCommittees;
