import { useState, useEffect } from "react";
import Calendar from "../components/Calendar";
import { Image } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { fetchOrganization } from "../http/navLinksAPI";
import parse from "html-react-parser";

const Organization = () => {
	const [organization, setOrganization] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchOrganization()
			.then((data) => {
				setOrganization(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<div className="d-flex flex-md-row flex-column justify-content-center px-4">
				<div className="d-flex flex-column col-md-8 px-4">
					<div className="py-4 block-title text-center">
						<span className="text-primary">Organigrama</span>
					</div>
					{organization.count > 0 ? (
						<>
							{organization.rows.map((item, idx) => (
								<div className="d-flex flex-column justify-content-center">
									<Image
										src={process.env.REACT_APP_API_URL + item.img}
										style={{
											maxHeight: "50vh",
											width: "auto",
											aspectRatio: 1,
											objectFit: "contain",
										}}
									/>
									{item.description ? (
										<span className="text-wrap">{parse(item.description)}</span>
									) : null}
								</div>
							))}
							<div className="d-flex flex-xl-row flex-column align-items-center justify-content-end w-100">
								<div className="d-flex flex-row align-items-center justify-content-end ps-1">
									<i
										className="bi bi-printer text-primary icon-3"
										style={{
											fontWeight: "600 !important",
											WebkitTextStroke: 1,
										}}
									/>
									<div
										className="d-flex rounded ms-3 px-4 bg-primary text-white align-items-center"
										style={{ width: 146 }}
									>
										<i className="bi bi-share icon-2" />
										<span className="ps-4 font-1 fw-bold">Share</span>
									</div>
								</div>
							</div>
						</>
					) : null}
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default Organization;
