import { useEffect, useState } from "react";
import { Form, Spinner, Accordion, Card, Image, useAccordionButton, Tabs, Tab } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import parse from "html-react-parser";
import {
	BUSINESS_ROUTE,
	DECENTRALIZED_SERVICES_ROUTE,
	HISTORY_ROUTE,
	MUNICIPAL_ENTERPRISES_ROUTE,
	NGOV_ORGANIZATIONS_ROUTE,
	PRESIDENTS_MESSAGE_ROUTE,
} from "../../utils/consts";
import {
	fetchDecentralizedServices,
	fetchMajorBusiness,
	fetchMunicipalEnterprises,
	fetchNGovOrganizations,
	fetchOneBusiness,
	fetchOneHistory,
	removeOneBusiness,
	removeOneDecentralizedServices,
	removeOneHistory,
	removeOneMajorBusiness,
	removeOneMunicipalEnterprises,
	removeOneNGovOrganizations,
	updateOneBusiness,
	updateOneDecentralizedServices,
	updateOneHistory,
	updateOneMajorBusiness,
	updateOneMunicipalEnterprises,
	updateOneNGovOrganizations,
} from "../../http/navLinksAPI";
import { fetchOneMayorMessage, removeOneMayorMessage, updateOneMayorMessage } from "../../http/activitiesAPI";
import AddCommunityModal from "./Modals/AddCommunityModal";

const CommunityAdmin = observer(() => {
	const [show, setShow] = useState(false);

	const [mayorMessage, setMayorMessage] = useState(null);
	const [history, setHistory] = useState(null);
	const [services, setServices] = useState(null);
	const [municipalEnterprises, setMunicipalEnterprises] = useState(null);
	const [nGovOrgs, setNGovOrgs] = useState(null);
	const [business, setBusiness] = useState(null);
	const [majorBusiness, setMajorBusiness] = useState(null);

	// DS

	const [name, setName] = useState("");
	const [fullName, setFullName] = useState("");
	const [phone, setPhone] = useState("");
	const [type, setType] = useState("");
	const [contactPerson, setContactPerson] = useState("");
	const [address, setAddress] = useState("");
	const [article, setArticle] = useState("");

	const [loading, setLoading] = useState(true);
	const [selectedTab, setSelectedTab] = useState("Mesajul Presedintelui");

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [author, setAuthor] = useState("");
	const [img, setImg] = useState([null]);

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
				onClick={decoratedOnClick}
			>
				{children}
			</button>
		);
	};

	const editItem = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		formData.append("author", author);

		// DS
		formData.append("name", name);
		formData.append("full_name", fullName);
		formData.append("phone", phone);
		formData.append("type", type);
		formData.append("address", address);
		formData.append("contact_person", contactPerson);
		formData.append("article", article);

		switch (selectedTab) {
			case "Mesajul Presedintelui":
				updateOneMayorMessage(formData, id).then(() => window.location.reload());
				break;
			case "Istoria R-ului":
				updateOneHistory(formData, id).then(() => window.location.reload());
				break;
			case "Serviciile descentralizate":
				updateOneDecentralizedServices(formData, id).then(() => window.location.reload());
				break;
			case "Întreprinderi municipale":
				updateOneMunicipalEnterprises(formData, id).then(() => window.location.reload());
				break;
			case "Organizații neguvernamentale":
				updateOneNGovOrganizations(formData, id).then(() => window.location.reload());
				break;
			case "Afaceri":
				article !== ""
					? updateOneBusiness(formData, id).then(() => window.location.reload())
					: updateOneMajorBusiness(formData, id).then(() => window.location.reload());
				break;
		}
	};

	const removeItem = (item) => {
		const id = item.id;
		switch (selectedTab) {
			case "Mesajul Presedintelui":
				removeOneMayorMessage(id).then(() => window.location.reload());
				break;
			case "Istoria R-ului":
				removeOneHistory(id).then(() => window.location.reload());
				break;
			case "Serviciile descentralizate":
				removeOneDecentralizedServices(id).then(() => window.location.reload());
				break;
			case "Întreprinderi municipale":
				removeOneMunicipalEnterprises(id).then(() => window.location.reload());
				break;
			case "Organizații neguvernamentale":
				removeOneNGovOrganizations(id).then(() => window.location.reload());
				break;
			case "Afaceri":
				article !== ""
					? removeOneBusiness(id).then(() => window.location.reload())
					: removeOneMajorBusiness(id).then(() => window.location.reload());
				break;
		}
	};

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	useEffect(() => {
		fetchOneMayorMessage(1).then((data) => {
			setMayorMessage(data);
		});
		fetchOneHistory(1).then((data) => {
			setHistory(data);
		});
		fetchDecentralizedServices().then((data) => {
			setServices(data);
		});
		fetchMunicipalEnterprises().then((data) => {
			setMunicipalEnterprises(data);
		});
		fetchNGovOrganizations().then((data) => {
			setNGovOrgs(data);
		});
		fetchOneBusiness(1).then((data) => {
			setBusiness(data);
		});
		fetchMajorBusiness()
			.then((data) => {
				setMajorBusiness(data);
			})
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div className="d-flex flex-column bg-white r-corner-radius w-100">
			<div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
				<span>Comunitate</span>
				<button
					className="btn btn-primary r-u-corner-radius"
					style={{ height: 70, width: 70 }}
					onClick={() => setShow(true)}
				>
					<i className="bi bi-plus-circle icon-2" />
				</button>
			</div>
			<div className="d-flex flex-column px-2 pt-3">
				<Tabs
					defaultActiveKey="Mesajul Presedintelui"
					id="uncontrolled-tab-example"
					className="d-flex justify-content-center mb-3 text-primary fw-bold"
					onSelect={(e) => setSelectedTab(e)}
				>
					<Tab eventKey="Mesajul Presedintelui" title="Mesajul Presedintelui">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{mayorMessage ? (
									<div className="shadow-sm border-0 my-2 px-4 rounded">
										<div className="d-flex flex-column">
											<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
												<div className="d-flex flex-xl-row flex column align-items-center">
													{mayorMessage.img ? (
														<Image
															className="rounded"
															src={process.env.REACT_APP_API_URL + mayorMessage.img}
															style={{ maxHeight: "30vh" }}
														/>
													) : null}
													<div className="d-flex flex-column  ps-xl-4">
														<a
															role="button"
															href={PRESIDENTS_MESSAGE_ROUTE}
															className="text-decoration-none text-primary"
														>
															<Card.Title>{mayorMessage.title}</Card.Title>
														</a>
														{mayorMessage.description
															? parse(
																	Array.from(mayorMessage.description)
																		.splice(0, 60)
																		.join("") + "...",
															  )
															: null}
													</div>
												</div>
												<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
													<CustomToggle
														eventKey="mayor_message"
														onClick={() => {
															useAccordionButton("mayor_message");
														}}
													>
														<i className="bi bi-pencil-square icon-2 pe-2" />
														<span>Edit</span>
													</CustomToggle>
													<button
														className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
														onClick={() => removeItem(mayorMessage)}
													>
														<i className="bi bi-x-circle icon-2 pe-2" />
														<span>Remove</span>
													</button>
												</div>
											</div>
										</div>
										<Accordion.Collapse eventKey="mayor_message">
											<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
												<div className="d-flex flex-column justify-content-around px-4 py-4">
													<span className="text-primary  font-2 py-2 ps-1">Denumire</span>
													<Form.Control
														aria-label="Title"
														placeholder={mayorMessage.title}
														value={title}
														onChange={(e) => {
															setTitle(e.target.value);
														}}
													/>
													<span className="text-primary  font-2 py-2 ps-1">Descriere</span>
													<Form.Control
														aria-label="Title"
														placeholder={mayorMessage.description}
														value={description}
														onChange={(e) => {
															setDescription(e.target.value);
														}}
													/>
													<span className="text-primary  font-2 py-2 ps-1">Autor</span>
													<Form.Control
														aria-label="Title"
														placeholder={mayorMessage.author}
														value={author}
														onChange={(e) => {
															setAuthor(e.target.value);
														}}
													/>
													<span className="text-primary  font-2 py-2 ps-1">Fotografie</span>
													<Form.Control
														type={"file"}
														onChange={selectFile}
														aria-label="PageTitle"
														aria-describedby="basic-addon"
													/>
												</div>
												<button
													className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
													onClick={() => editItem(mayorMessage)}
												>
													<i className="bi bi-check icon-2 pe-2" />
													<span>Confirm</span>
												</button>
											</div>
										</Accordion.Collapse>
									</div>
								) : null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Istoria R-ului" title="Istoria R-ului">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{history ? (
									<div className="shadow-sm border-0 my-2 px-4 rounded">
										<div className="d-flex flex-column">
											<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
												<div className="d-flex flex-xl-row flex column align-items-center">
													{history.img ? (
														<Image
															className="rounded"
															src={process.env.REACT_APP_API_URL + history.img}
															style={{ maxHeight: "30vh" }}
														/>
													) : null}
													<div className="d-flex flex-column  ps-xl-4">
														<a
															role="button"
															href={HISTORY_ROUTE}
															className="text-decoration-none text-primary"
														>
															<Card.Title>{history.title}</Card.Title>
														</a>
														{history.description
															? parse(
																	Array.from(history.description)
																		.splice(0, 60)
																		.join("") + "...",
															  )
															: null}
													</div>
												</div>
												<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
													<CustomToggle
														eventKey="mayor_message"
														onClick={() => {
															useAccordionButton("mayor_message");
														}}
													>
														<i className="bi bi-pencil-square icon-2 pe-2" />
														<span>Edit</span>
													</CustomToggle>
													<button
														className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
														onClick={() => removeItem(history)}
													>
														<i className="bi bi-x-circle icon-2 pe-2" />
														<span>Remove</span>
													</button>
												</div>
											</div>
										</div>
										<Accordion.Collapse eventKey="mayor_message">
											<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
												<div className="d-flex flex-column justify-content-around px-4 py-4">
													<span className="text-primary  font-2 py-2 ps-1">Denumire</span>
													<Form.Control
														aria-label="Title"
														placeholder={history.title}
														value={title}
														onChange={(e) => {
															setTitle(e.target.value);
														}}
													/>
													<span className="text-primary  font-2 py-2 ps-1">Descriere</span>
													<Form.Control
														aria-label="Title"
														placeholder={history.description}
														value={description}
														onChange={(e) => {
															setDescription(e.target.value);
														}}
													/>
													<span className="text-primary  font-2 py-2 ps-1">Autor</span>
													<Form.Control
														aria-label="Title"
														placeholder={history.author}
														value={author}
														onChange={(e) => {
															setAuthor(e.target.value);
														}}
													/>
													<span className="text-primary  font-2 py-2 ps-1">Fotografie</span>
													<Form.Control
														type={"file"}
														onChange={selectFile}
														aria-label="PageTitle"
														aria-describedby="basic-addon"
													/>
												</div>
												<button
													className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
													onClick={() => editItem(history)}
												>
													<i className="bi bi-check icon-2 pe-2" />
													<span>Confirm</span>
												</button>
											</div>
										</Accordion.Collapse>
									</div>
								) : null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Serviciile descentralizate" title="Serviciile descentralizate">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{services
									? services.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	href={DECENTRALIZED_SERVICES_ROUTE}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title>{item.name}</Card.Title>
																</a>
																<div>
																	<span>{item.full_name}</span>
																	<span>{item.phone}</span>
																</div>
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary  font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.name}
																value={name}
																onChange={(e) => {
																	setName(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Responsabil
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.full_name}
																value={fullName}
																onChange={(e) => {
																	setFullName(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Nr. de Telefon
															</span>
															<Form.Control
																aria-label="Phone"
																placeholder={item.phone}
																value={phone}
																onChange={(e) => {
																	setPhone(e.target.value);
																}}
															/>
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Întreprinderi municipale" title="Întreprinderi municipale">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{municipalEnterprises
									? municipalEnterprises.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	href={MUNICIPAL_ENTERPRISES_ROUTE}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title>{item.name}</Card.Title>
																</a>
																<div>
																	<span>{item.type}</span>
																</div>
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary  font-2 py-2 ps-1">
																Tipul Interprinderii
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.type}
																value={type}
																onChange={(e) => {
																	setType(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.name}
																value={name}
																onChange={(e) => {
																	setName(e.target.value);
																}}
															/>
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Organizații neguvernamentale" title="Organizații neguvernamentale">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{nGovOrgs
									? nGovOrgs.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	href={NGOV_ORGANIZATIONS_ROUTE}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title>{item.name}</Card.Title>
																</a>
																<div>
																	<span>{item.address}</span>
																	<span>{item.contact_person}</span>
																	<span>{item.phone}</span>
																</div>
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary  font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.name}
																value={name}
																onChange={(e) => {
																	setName(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Adresa
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.address}
																value={address}
																onChange={(e) => {
																	setAddress(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Persoana de contact
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.contact_person}
																value={contactPerson}
																onChange={(e) => {
																	setContactPerson(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Nr. de Telefon
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.phone}
																value={phone}
																onChange={(e) => {
																	setPhone(e.target.value);
																}}
															/>
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Afaceri" title="Afaceri">
						<div className="d-flex flex-column">
							{business && business.article ? (
								<Accordion className="pb-4">
									<div className="shadow-sm border-0 my-2 px-4 rounded">
										<div className="d-flex flex-column">
											<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
												<div className="d-flex flex-xl-row flex column align-items-center">
													<div className="d-flex flex-column ps-xl-4">
														<span className="fw-bold font-2 text-primary pb-3">
															Articol în secțiunea Afaceri
														</span>
														<a
															role="button"
															href={BUSINESS_ROUTE}
															className="text-decoration-none text-primary"
														>
															<Card.Title>{business.article}</Card.Title>
														</a>
													</div>
												</div>
												<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
													<CustomToggle
														eventKey="business"
														onClick={() => {
															useAccordionButton("business");
														}}
													>
														<i className="bi bi-pencil-square icon-2 pe-2" />
														<span>Edit</span>
													</CustomToggle>
													<button
														className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
														onClick={() => removeItem(business)}
													>
														<i className="bi bi-x-circle icon-2 pe-2" />
														<span>Remove</span>
													</button>
												</div>
											</div>
										</div>
										<Accordion.Collapse eventKey="business">
											<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
												<div className="d-flex flex-column justify-content-around px-4 py-4">
													<span className="text-primary  font-2 py-2 ps-1">Articol</span>
													<Form.Control
														aria-label="Title"
														placeholder={business.article}
														value={article}
														onChange={(e) => {
															setArticle(e.target.value);
														}}
													/>
												</div>
												<button
													className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
													onClick={() => editItem(business)}
												>
													<i className="bi bi-check icon-2 pe-2" />
													<span>Confirm</span>
												</button>
											</div>
										</Accordion.Collapse>
									</div>
								</Accordion>
							) : null}
							<Accordion className="pb-4">
								{majorBusiness.count > 0
									? majorBusiness.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	href={BUSINESS_ROUTE}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title>{item.name}</Card.Title>
																</a>
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary  font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.name}
																value={name}
																onChange={(e) => {
																	setName(e.target.value);
																}}
															/>
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					{/* <Tab
						eventKey='Mesajul Presedintelui'
						title='Mesajul Presedintelui'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{mayorMessage ? (
									<div
										key={idx}
										className='shadow-sm border-0 my-2 px-4 rounded'>
										<div className='d-flex flex-column'>
											<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
												<div className='d-flex flex-xl-row flex column align-items-center'>
													{item.img ? (
														<Image
															className='rounded'
															src={process.env.REACT_APP_API_URL + mayorMessage.img}
															style={{ maxHeight: "30vh" }}
														/>
													) : null}
													<div className='d-flex flex-column  ps-xl-4'>
														<a
															role='button'
															href={PRESIDENTS_MESSAGE_ROUTE}
															className='text-decoration-none text-primary'>
															<Card.Title>{mayorMessage.title}</Card.Title>
														</a>
														{mayorMessage.description
															? parse(Array.from(mayorMessage.description).splice(0, 60).join("") + "...")
															: null}
													</div>
												</div>
												<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
													<CustomToggle
														eventKey={idx}
														onClick={() => {
															useAccordionButton({ idx });
														}}>
														<i className='bi bi-pencil-square icon-2 pe-2' />
														<span>Edit</span>
													</CustomToggle>
													<button
														className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
														onClick={() => removeItem(item)}>
														<i className='bi bi-x-circle icon-2 pe-2' />
														<span>Remove</span>
													</button>
												</div>
											</div>
										</div>
										<Accordion.Collapse eventKey={idx}>
											<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
												<div className='d-flex flex-column justify-content-around px-4 py-4'>
													<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
													<Form.Control
														aria-label='Title'
														placeholder={mayorMessage.title}
														value={title}
														onChange={(e) => {
															setTitle(e.target.value);
														}}
													/>
													<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
													<Form.Control
														aria-label='Title'
														placeholder={mayorMessage.description}
														value={description}
														onChange={(e) => {
															setDescription(e.target.value);
														}}
													/>
													<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
													<Form.Control
														type={"file"}
														onChange={selectFile}
														aria-label='PageTitle'
														aria-describedby='basic-addon'
													/>
												</div>
												<button
													className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
													onClick={() => editItem(mayorMessage)}>
													<i className='bi bi-check icon-2 pe-2' />
													<span>Confirm</span>
												</button>
											</div>
										</Accordion.Collapse>
									</div>
								) : null}
							</Accordion>
						</div>
					</Tab> */}
				</Tabs>
			</div>
			<AddCommunityModal show={show} onHide={() => setShow(false)} selectedTab={selectedTab} />
		</div>
	);
});

export default CommunityAdmin;
