import { useState, useEffect } from "react";
import Calendar from "../components/Calendar";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BIOGRAPHY_ROUTE } from "../utils/consts";
import { fetchBiography } from "../http/navLinksAPI";
import { Spinner } from "react-bootstrap";

const Management = () => {
	const navigate = useNavigate();
	const [servicesResp, setServicesResp] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchBiography()
			.then((data) => {
				setServicesResp(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<div className="d-flex flex-md-row flex-column justify-content-center px-4">
				<div className="d-flex flex-column col-md-8 px-4">
					<div className="py-4 block-title text-center">
						<span className="text-primary">Conducerea</span>
					</div>
					<Table bordered hover>
						<thead>
							<tr>
								<th className="bg-primary text-white">Numele, Prenumele</th>
								<th className="bg-primary text-white">Funcția</th>
								<th className="bg-primary text-white">Numărul de telefon</th>
								<th className="bg-primary text-white text-center">CV</th>
							</tr>
						</thead>
						{servicesResp.count > 0
							? servicesResp.rows.map((item, idx) => (
									<tbody>
										<tr>
											<td>
												{item.last_name} {item.first_name}
											</td>
											<td>{item.role}</td>
											<td>{item.phone}</td>
											<td
												className="bg-white text-white text-center"
												role="button"
												onClick={() => navigate(BIOGRAPHY_ROUTE + "/" + item.id)}
											>
												<i className="bi text-primary bi-file-earmark-text icon-2" />
											</td>
										</tr>
									</tbody>
							  ))
							: null}
					</Table>
					<div className="d-flex flex-xl-row flex-column align-items-center justify-content-end w-100">
						<i
							className="bi bi-printer text-primary icon-3 px-4"
							style={{
								fontWeight: "600 !important",
								WebkitTextStroke: 1,
							}}
						/>
						<div
							className="d-flex rounded ms-3 px-4 bg-primary text-white align-items-center"
							style={{ width: 146 }}
						>
							<i className="bi bi-share icon-2" />
							<span className="ps-4 font-1 fw-bold">Share</span>
						</div>
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default Management;
