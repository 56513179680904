import React, { useContext, useEffect, useState } from "react";
import { Card, Button, Tabs, Tab } from "react-bootstrap";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import Calendar from "../components/Calendar";
import { useNavigate } from "react-router-dom";
import { CITY_NEWS_ROUTE, PHOTO_GALLERY_ROUTE } from "../utils/consts";
import { Spinner } from "react-bootstrap";
import {
	fetchCityNews,
	fetchCommitteeMeetings,
	fetchMayorOnline,
	fetchOnlineMeetings,
	fetchOther,
	fetchPhotoGallery,
} from "../http/NewsAPI";

const CityNewsList = observer(() => {
	const { cityNews } = useContext(Context);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		fetchCityNews().then((data) => {
			cityNews.setCityNews(data);
		});
		fetchCommitteeMeetings().then((data) => {
			cityNews.setCommitteeMeetings(data);
		});
		fetchPhotoGallery().then((data) => {
			cityNews.setPhotoGallery(data);
		});
		fetchOnlineMeetings().then((data) => {
			cityNews.setOnlineMeetings(data);
		});
		fetchMayorOnline().then((data) => {
			cityNews.setMayorOnline(data);
		});
		fetchOther()
			.then((data) => {
				cityNews.setOther(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className="d-flex flex-md-row flex-column justify-content-center px-4">
			<div className="py-4 px-5 border-bottom col-md-9">
				<div className="block-title pb-4">
					<span>Noutațile orașului</span>
				</div>
				<Tabs defaultActiveKey="news" id="fill-tab-example" className="mb-3 text-primary" fill>
					<Tab eventKey="news" title="Noutăți" className="text-primary fw-bold">
						<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
							{cityNews.cityNews.rows.map((item, idx) => {
								return (
									<Card
										key={idx}
										style={{ width: "22rem" }}
										className="my-3"
										onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}
									>
										<Card.Img variant="top" src="https://picsum.photos/400/250" />
										<Card.Body>
											<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
												<span
													className="border border-primary rounded text-primary p-2"
													style={{ fontSize: "1rem" }}
												>
													{item.date}
												</span>
												{item.isNew ? (
													<span
														className="border bg-primary rounded text-white p-2"
														style={{ fontSize: "1rem" }}
													>
														NEW
													</span>
												) : (
													""
												)}
											</Card.Title>
											<Card.Text onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}>
												{item.title}
											</Card.Text>
										</Card.Body>
									</Card>
								);
							})}
						</div>
					</Tab>
					<Tab eventKey="commission-meetings" title="Ședințe comisii" className="text-primary fw-bold">
						<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
							{cityNews.committeeMeetings.rows.map((item, idx) => {
								return (
									<Card key={idx} style={{ width: "22rem" }} className="my-3">
										<Card.Img variant="top" src="https://picsum.photos/400/250" />
										<Card.Body>
											<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
												<span
													className="border border-primary rounded text-primary p-2"
													style={{ fontSize: "1rem" }}
												>
													{item.date}
												</span>
												{item.isNew ? (
													<span
														className="border bg-primary rounded text-white p-2"
														style={{ fontSize: "1rem" }}
													>
														NEW
													</span>
												) : (
													""
												)}
											</Card.Title>
											<Card.Text onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}>
												{item.title}
											</Card.Text>
										</Card.Body>
									</Card>
								);
							})}
						</div>
					</Tab>
					<Tab eventKey="photos" title="Fotografii" className="text-primary fw-bold">
						<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
							{cityNews.photoGallery.rows.length > 0
								? cityNews.photoGallery.rows.map((item, idx) => {
										return (
											<Card
												key={idx}
												style={{ width: "22rem" }}
												className="my-3"
												onClick={() => navigate(PHOTO_GALLERY_ROUTE + "/" + item.id)}
											>
												<Card.Img variant="top" src={item.photo_collection[0].img} />
												<Card.Body>
													<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
														<span
															className="border border-primary rounded text-primary p-2"
															style={{ fontSize: "1rem" }}
														>
															{item.date}
														</span>
														{item.isNew ? (
															<span
																className="border bg-primary rounded text-white p-2"
																style={{ fontSize: "1rem" }}
															>
																NEW
															</span>
														) : (
															""
														)}
													</Card.Title>
													<Card.Text
														onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}
													>
														{item.title}
													</Card.Text>
												</Card.Body>
											</Card>
										);
								  })
								: null}
						</div>
					</Tab>
					<Tab eventKey="online-meetings" title="Ședințe online" className="text-primary fw-bold">
						<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
							{cityNews.onlineMeetings.rows.length > 0
								? cityNews.onlineMeetings.rows.map((item, idx) => {
										return (
											<Card key={idx} style={{ width: "22rem", minHeight: 330 }} className="my-3">
												{/* <Card.Img variant="top" src={item.video_link} /> */}
												<iframe src={item.video_link} className="u-corner-radius"></iframe>
												<Card.Body>
													<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
														<span
															className="border border-primary rounded text-primary p-2"
															style={{ fontSize: "1rem" }}
														>
															{item.date}
														</span>
														{item.isNew ? (
															<span
																className="border bg-primary rounded text-white p-2"
																style={{ fontSize: "1rem" }}
															>
																NEW
															</span>
														) : (
															""
														)}
													</Card.Title>
													<Card.Text
														onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}
													>
														{Array.from(item.title).splice(0, 70).join("") + "..."}
													</Card.Text>
												</Card.Body>
											</Card>
										);
								  })
								: null}
						</div>
					</Tab>
					<Tab eventKey="mayor-online" title="Primarul online" className="text-primary fw-bold">
						<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
							{cityNews.mayorOnline.rows.map((item, idx) => {
								return (
									<Card key={idx} style={{ width: "22rem", minHeight: 330 }} className="my-3">
										{/* <Card.Img variant="top" src={item.video_link} /> */}
										<iframe src={item.video_link} className="u-corner-radius"></iframe>
										<Card.Body>
											<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
												<span
													className="border border-primary rounded text-primary p-2"
													style={{ fontSize: "1rem" }}
												>
													{item.date}
												</span>
												{item.isNew ? (
													<span
														className="border bg-primary rounded text-white p-2"
														style={{ fontSize: "1rem" }}
													>
														NEW
													</span>
												) : (
													""
												)}
											</Card.Title>
											<Card.Text onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}>
												{Array.from(item.title).splice(0, 70).join("") + "..."}
											</Card.Text>
										</Card.Body>
									</Card>
								);
							})}
						</div>
					</Tab>
					<Tab eventKey="another" title="Alte" className="text-primary fw-bold">
						<div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
							{cityNews.other.rows.map((item, idx) => {
								return (
									<Card key={idx} style={{ width: "22rem", minHeight: 330 }} className="my-3">
										{/* <Card.Img variant="top" src={item.video_link} /> */}
										<iframe src={item.video_link} className="u-corner-radius"></iframe>
										<Card.Body>
											<Card.Title className="mb-3 d-flex align-items-center justify-content-between">
												<span
													className="border border-primary rounded text-primary p-2"
													style={{ fontSize: "1rem" }}
												>
													{item.date}
												</span>
												{item.isNew ? (
													<span
														className="border bg-primary rounded text-white p-2"
														style={{ fontSize: "1rem" }}
													>
														NEW
													</span>
												) : (
													""
												)}
											</Card.Title>
											<Card.Text onClick={() => navigate(CITY_NEWS_ROUTE + "/" + item.id)}>
												{Array.from(item.title).splice(0, 70).join("") + "..."}
											</Card.Text>
										</Card.Body>
									</Card>
								);
							})}
						</div>
					</Tab>
				</Tabs>
			</div>
			<Calendar />
		</div>
	);
});

export default CityNewsList;
