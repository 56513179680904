import { useContext, useEffect, useState } from "react";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { Spinner } from "react-bootstrap";
import { fetchReferences } from "../http/referencesAPI";

const References = observer(() => {
	const { references } = useContext(Context);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchReferences()
			.then((data) => {
				references.setReferences(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className="d-flex flex-column justify-content-around my-5 px-5">
			<div className="block-title pb-3">
				<span>Referințe</span>
			</div>
			<div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
				{references.references.map((item, idx) => (
					<a
						key={"ref-" + idx}
						href={item.link}
						className="bg-third rounded text-primary px-4 mx-2 my-1 py-2"
					>
						{item.title}
					</a>
				))}
			</div>
		</div>
	);
});

export default References;
