import { $authHost, $host } from "./index";

// export const createEx = async (ex) => {
// 	const { data } = await $authHost.post("api/ex", ex);
// 	return data;
// };

// export const fetchEX = async () => {
// 	const { data } = await $host.get("api/ex");
// 	return data;
// };

export const createCityNews = async (cityNews) => {
	const { data } = await $authHost.post("api/city_news", cityNews);
	return data;
};

export const fetchCityNews = async () => {
	const { data } = await $host.get("api/city_news");
	return data;
};

export const fetchOneCityNews = async (id) => {
	const { data } = await $host.get("api/city_news/" + id);
	return data;
};

export const removeOneCityNews = async (id) => {
	const { data } = await $host.post("api/city_news/" + id);
	return data;
};

export const removeOneCityNewsFile = async (id) => {
	const { data } = await $host.post("api/city_news/rD/" + id);
	return data;
};

export const updateOneCityNews = async (processData, id) => {
	const { data } = await $host.post("api/city_news/u/" + id, processData);
	return data;
};

// Next Item

export const createCommitteeMeetings = async (committeeMeetings) => {
	const { data } = await $authHost.post("api/committee_meetings", committeeMeetings);
	return data;
};

export const fetchCommitteeMeetings = async () => {
	const { data } = await $host.get("api/committee_meetings");
	return data;
};

export const fetchOneCommitteeMeetings = async (id) => {
	const { data } = await $host.get("api/committee_meetings/" + id);
	return data;
};

export const removeOneCommitteeMeetings = async (id) => {
	const { data } = await $host.post("api/online_meetings/" + id);
	return data;
};

export const removeOneCommitteeMeetingsFile = async (id) => {
	const { data } = await $host.post("api/online_meetings/rD/" + id);
	return data;
};

export const updateOneCommitteeMeetings = async (processData, id) => {
	const { data } = await $host.post("api/online_meetings/u/" + id, processData);
	return data;
};

// Next Item

export const createPhotoGallery = async (photoGallery) => {
	const { data } = await $authHost.post("api/photo_gallery", photoGallery);
	return data;
};

export const fetchPhotoGallery = async () => {
	const { data } = await $host.get("api/photo_gallery");
	return data;
};

export const fetchOnePhotoGallery = async (id) => {
	const { data } = await $host.get("api/photo_gallery/" + id);
	return data;
};

export const removeOnePhotoGallery = async (id) => {
	const { data } = await $host.post("api/photo_gallery/" + id);
	return data;
};

export const updateOnePhotoGallery = async (processData, id) => {
	const { data } = await $host.post("api/photo_gallery/u/" + id, processData);
	return data;
};

export const removeOnePhotoGalleryFile = async (id) => {
	const { data } = await $host.post("api/photo_gallery/rD/" + id);
	return data;
};

// Next Item

export const createOnlineMeetings = async (onlineMeetings) => {
	const { data } = await $authHost.post("api/online_meetings", onlineMeetings);
	return data;
};

export const fetchOnlineMeetings = async () => {
	const { data } = await $host.get("api/online_meetings");
	return data;
};

export const fetchOneOnlineMeetings = async (id) => {
	const { data } = await $host.get("api/online_meetings/" + id);
	return data;
};

export const removeOneOnlineMeetings = async (id) => {
	const { data } = await $host.post("api/online_meetings/" + id);
	return data;
};

export const updateOneOnlineMeetings = async (processData, id) => {
	const { data } = await $host.post("api/online_meetings/u/" + id, processData);
	return data;
};

// Next Item

export const createMayorOnline = async (mayorOnline) => {
	const { data } = await $authHost.post("api/mayor_online", mayorOnline);
	return data;
};

export const fetchMayorOnline = async () => {
	const { data } = await $host.get("api/mayor_online");
	return data;
};

export const fetchOneMayorOnline = async (id) => {
	const { data } = await $host.get("api/mayor_online/" + id);
	return data;
};

export const removeOneMayorOnline = async (id) => {
	const { data } = await $host.post("api/mayor_online/" + id);
	return data;
};

export const updateOneMayorOnline = async (processData, id) => {
	const { data } = await $host.post("api/mayor_online/u/" + id, processData);
	return data;
};

// Next Item

export const createOther = async (other) => {
	const { data } = await $authHost.post("api/other", other);
	return data;
};

export const fetchOther = async () => {
	const { data } = await $host.get("api/other");
	return data;
};

export const fetchOneOther = async (id) => {
	const { data } = await $host.get("api/other/" + id);
	return data;
};

export const removeOneOther = async (id) => {
	const { data } = await $host.post("api/other/" + id);
	return data;
};

export const updateOneOther = async (processData, id) => {
	const { data } = await $host.post("api/other/u/" + id, processData);
	return data;
};

// Next Item

export const createLastNews = async (cityNews) => {
	const { data } = await $authHost.post("api/last_news", cityNews);
	return data;
};

export const fetchLastNews = async () => {
	const { data } = await $host.get("api/last_news");
	return data;
};

export const fetchOneLastNews = async (id) => {
	const { data } = await $host.get("api/last_news/" + id);
	return data;
};

export const removeOneLastNews = async (id) => {
	const { data } = await $host.post("api/last_news/" + id);
	return data;
};

export const updateOneLastNews = async (processData, id) => {
	const { data } = await $host.post("api/last_news/u/" + id, processData);
	return data;
};
