import React, { useContext } from "react";
import { Context } from "..";
import Calendar from "../components/Calendar";

const DistrictProvisions = () => {
	// const { regulations } = useContext(Context);
	return (
		<div className="d-flex flex-xl-row flex-column justify-content-around px-4 border-bottom">
			<div className="d-flex flex-column px-xl-5 py-4 col-xl-9 col-12">
				<div className="block-title pb-4">
					<span>Dispozitii CRE</span>
				</div>

				<div className="d-flex flex-column justify-content-start shadow rounded">
					<iframe
						id="widget_iframe"
						className="rounded"
						style={{
							borderWidth: 0,
							width: "100%",
							height: 1000,
							backgroundColor: "white",
							borderColor: "black",
							borderRadius: 0,
						}}
						src="https://actelocale.gov.md/widget_src/eyJzaG93X3BhZ2luYXRpb24iOnRydWUsInNob3dfc2VhcmNoIjpmYWxzZSwicmVzdWx0c19vbl9wYWdlIjoyMCwib2Zmc2V0IjowLCJmb250X2NvbG9yIjoicmdiYSgwLDAsMCwxKSIsImZvbnRfc2l6ZSI6MTQsInR5cGVzIjpbIjIiXSwiYXBsIjoiMTg1MDcifQ%3D%3D"
					/>
				</div>
			</div>
			<Calendar />
		</div>
	);
};

export default DistrictProvisions;
