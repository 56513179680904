import { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { createReferences } from "../../../http/referencesAPI";

const AddReferenceModal = ({ show, onHide }) => {
	const [title, setTitle] = useState("");
	const [link, setLink] = useState("");

	const addReference = () => {
		const formData = new FormData();
		formData.append("title", title);
		formData.append("link", link);
		createReferences(formData).then((data) => onHide());
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			className='add-news-modal border-primary'>
			<Modal.Header
				closeButton={onHide}
				className='bg-primary'
				closeVariant='white'>
				<Modal.Title className='text-white'>Adauga o referita</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='d-flex flex-column justify-content-around'>
					<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
					<Form.Control
						aria-label='Title'
						placeholder='Denumire'
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Lincul catre resurs</span>
					<Form.Control
						aria-label='Link'
						placeholder='Lincul catre resurs'
						value={link}
						onChange={(e) => setLink(e.target.value)}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={onHide}>
					Close
				</Button>
				<Button
					variant='primary'
					onClick={() => {
						onHide();
						addReference();
					}}>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddReferenceModal;
