import { useEffect, useState, useContext } from "react";
import {
	Form,
	Spinner,
	Accordion,
	Card,
	Image,
	useAccordionButton,
	Tabs,
	Tab,
	Dropdown,
	DropdownButton,
} from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { useNavigate } from "react-router-dom";
import {
	ANNOUNCEMENTS_ROUTE,
	DECISIONS_ROUTE,
	INFORMATION_ROUTE,
	LOCATION_IN_NUMBERSS_ROUTE,
	SUCCESS_STORIES_ROUTE,
} from "../../utils/consts";
import parse from "html-react-parser";
import {
	fetchKeySectors,
	fetchLocationDevelopmentStrategy,
	fetchLocationInNumbers,
	fetchPartnerSupportedProjects,
	fetchStartingBusiness,
	fetchSuccessStories,
	removeOneKeySectors,
	removeOneLocationDevelopmentStrategy,
	removeOneLocationDevelopmentStrategyFile,
	removeOneLocationInNumbers,
	removeOnePartnerSupportedProjects,
	removeOneStartingBusiness,
	removeOneSuccessStories,
	updateOneKeySectors,
	updateOneLocationDevelopmentStrategy,
	updateOneLocationInNumbers,
	updateOnePartnerSupportedProjects,
	updateOneStartingBusiness,
	updateOneSuccessStories,
} from "../../http/resourcesServicesAPI";
import AddCECEModal from "./Modals/AddCECEModal";
import AddInvestmentsModal from "./Modals/AddInvestmentsModal";

const InvestmentsAdmin = observer(() => {
	const { resourcesServices } = useContext(Context);
	const [show, setShow] = useState(false);

	const [loading, setLoading] = useState(true);
	const [selectedTab, setSelectedTab] = useState("Edinet in Cifre");

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [img, setImg] = useState([null]);
	const [files, setFiles] = useState([null]);

	const [value, setValue] = useState("");
	const [icon, setIcon] = useState("");

	const [author, setAuthor] = useState("");

	const navigate = useNavigate();

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
				onClick={decoratedOnClick}
			>
				{children}
			</button>
		);
	};

	const editItem = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		formData.append("value", value);
		formData.append("icon", icon);
		formData.append("author", author);

		for (const file of files) {
			formData.append("files", file);
		}
		switch (selectedTab) {
			case "Edinet in Cifre":
				updateOneLocationInNumbers(formData, id).then(() => window.location.reload());
				break;
			case "Istorii de Succes":
				updateOneSuccessStories(formData, id).then(() => window.location.reload());
				break;
			case "Strategia de Dezvoltare RE":
				updateOneLocationDevelopmentStrategy(formData, id).then(() => window.location.reload());
				break;
			case "Sectoare Cheie":
				updateOneKeySectors(formData, id).then(() => window.location.reload());
				break;
			case "Proiecte Sustinute de Partener":
				updateOnePartnerSupportedProjects(formData, id).then(() => window.location.reload());
				break;
			case "Initierea Afacerii":
				updateOneStartingBusiness(formData, id).then(() => window.location.reload());
				break;
		}
	};

	const removeItem = (item) => {
		const id = item.id;
		switch (selectedTab) {
			case "Edinet in Cifre":
				removeOneLocationInNumbers(id).then(() => window.location.reload());
				break;
			case "Istorii de Succes":
				removeOneSuccessStories(id).then(() => window.location.reload());
				break;
			case "Strategia de Dezvoltare RE":
				removeOneLocationDevelopmentStrategy(id).then(() => window.location.reload());
			case "Sectoare Cheie":
				removeOneKeySectors(id).then(() => window.location.reload());
				break;
			case "Proiecte Sustinute de Partener":
				removeOnePartnerSupportedProjects(id).then(() => window.location.reload());
				break;
			case "Initierea Afacerii":
				removeOneStartingBusiness(id).then(() => window.location.reload());
				break;
		}
	};

	const removeItemFile = (item) => {
		const id = item.id;
		switch (selectedTab) {
			case "Strategia de Dezvoltare RE":
				removeOneLocationDevelopmentStrategyFile(id).then(() => window.location.reload());
		}
	};

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	const selectMultipleFiles = (e) => {
		setFiles(e.target.files);
	};

	useEffect(() => {
		fetchLocationInNumbers().then((data) => {
			resourcesServices.setLocationInNumbers(data);
		});
		fetchSuccessStories().then((data) => {
			resourcesServices.setSuccessStories(data);
		});
		fetchLocationDevelopmentStrategy().then((data) => {
			resourcesServices.setLocationDevelopmentStrategy(data);
		});
		fetchKeySectors().then((data) => {
			resourcesServices.setKeySectors(data);
		});
		fetchPartnerSupportedProjects().then((data) => {
			resourcesServices.setPartnerSupportedProjects(data);
		});
		fetchStartingBusiness()
			.then((data) => {
				resourcesServices.setStartingBusiness(data);
			})
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div className="d-flex flex-column bg-white r-corner-radius w-100">
			<div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
				<span>Investeste in r-ul Edinet</span>
				{selectedTab !== "Initiere Parteniriate Publice/Private" ? (
					<button
						className="btn btn-primary r-u-corner-radius"
						style={{ height: 70, width: 70 }}
						onClick={() => setShow(true)}
					>
						<i className="bi bi-plus-circle icon-2" />
					</button>
				) : null}
			</div>
			<div className="d-flex flex-column px-2 pt-3">
				<Tabs
					defaultActiveKey="Edinet in Cifre"
					id="uncontrolled-tab-example"
					className="d-flex justify-content-center mb-3 text-primary fw-bold"
					onSelect={(e) => setSelectedTab(e)}
				>
					<Tab eventKey="Edinet in Cifre" title="Edinet in Cifre">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{resourcesServices.locationInNumbers.count > 0
									? resourcesServices.locationInNumbers.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															{item.icon ? (
																<i
																	className={`bi ${item.icon} icon-3 text-primary rounded ms-4 px-2 text-primary`}
																/>
															) : null}
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	onClick={() =>
																		navigate(
																			LOCATION_IN_NUMBERSS_ROUTE + "/" + item.id,
																		)
																	}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title className="text-primary">
																		{item.title}
																	</Card.Title>
																</a>
																{item.value ? (
																	<span className="text-primary">{item.value}</span>
																) : null}
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top custom-dropdown">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">Date</span>
															<Form.Control
																aria-label="value"
																placeholder={item.value}
																value={value}
																onChange={(e) => {
																	setValue(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Pictograma
															</span>
															<DropdownButton
																id="dropdown-basic-button"
																drop="down"
																title={
																	<span>
																		{icon == "" ? (
																			"Select Icon"
																		) : (
																			<i
																				className={`bi ${icon} icon-3 rounded px-2 text-white`}
																			/>
																		)}
																	</span>
																}
															>
																{resourcesServices.icons.map((icon_item, idx) => (
																	<Dropdown.Item
																		key={"icon-" + idx}
																		onClick={() => setIcon(icon_item)}
																		className="d-flex align-items-center justify-content-center"
																		style={{ maxWidth: "3.5rem" }}
																	>
																		<i
																			className={`bi ${icon_item} icon-3 text-primary rounded`}
																		/>
																	</Dropdown.Item>
																))}
															</DropdownButton>
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Istorii de Succes" title="Istorii de Succes">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{resourcesServices.successStories.count > 0
									? resourcesServices.successStories.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															{item.img ? (
																<Image
																	className="rounded"
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	onClick={() =>
																		navigate(SUCCESS_STORIES_ROUTE + "/" + item.id)
																	}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title className="text-primary">
																		{item.title}
																	</Card.Title>
																</a>
																{item.description
																	? parse(
																			Array.from(item.description)
																				.splice(0, 60)
																				.join("") + "...",
																	  )
																	: null}
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Descriere
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.description}
																value={description}
																onChange={(e) => {
																	setDescription(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">Autor</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.author}
																value={author}
																onChange={(e) => {
																	setAuthor(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Fotografie
															</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
															/>
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Strategia de Dezvoltare RE" title="Strategia de Dezvoltare RE">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{resourcesServices.locationDevelopmentStrategy.count > 0
									? resourcesServices.locationDevelopmentStrategy.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															{item.img ? (
																<Image
																	className="rounded"
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	onClick={() =>
																		navigate(DECISIONS_ROUTE + "/" + item.id)
																	}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title className="text-primary">
																		{item.title}
																	</Card.Title>
																</a>
																{item.description
																	? parse(
																			Array.from(item.description)
																				.splice(0, 60)
																				.join("") + "...",
																	  )
																	: null}
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Descriere
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.description}
																value={description}
																onChange={(e) => {
																	setDescription(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Fotografie
															</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Documente
															</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
																multiple
															/>
															{item.location_development_strategy_files.length > 0
																? item.location_development_strategy_files.map(
																		(sub_item, sub_idx) => (
																			<div
																				key={sub_idx}
																				className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
																			>
																				<div className="d-flex align-items-center">
																					<i
																						className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																					/>
																					<a
																						href={
																							process.env
																								.REACT_APP_API_URL +
																							sub_item.doc
																						}
																					>
																						<span
																							className="text-wrap"
																							style={{
																								textAlign: "justify",
																							}}
																						>
																							{sub_item.doc.split(".")[0]}
																						</span>
																					</a>
																				</div>
																				<button
																					className="btn btn-primary"
																					onClick={() =>
																						removeItemFile(sub_item)
																					}
																				>
																					<i className="bi bi-x-circle font-2"></i>
																				</button>
																			</div>
																		),
																  )
																: null}
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Sectoare Cheie" title="Sectoare Cheie">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{resourcesServices.keySectors.count > 0
									? resourcesServices.keySectors.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															{item.img ? (
																<Image
																	className="rounded"
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	onClick={() =>
																		navigate(ANNOUNCEMENTS_ROUTE + "/" + item.id)
																	}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title className="text-primary">
																		{item.title}
																	</Card.Title>
																</a>
																{item.description
																	? parse(
																			Array.from(item.description)
																				.splice(0, 60)
																				.join("") + "...",
																	  )
																	: null}
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Descriere
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.description}
																value={description}
																onChange={(e) => {
																	setDescription(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Fotografie
															</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
															/>
															<span className="text-primary font-2 py-2 ps-1">Autor</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.author}
																value={author}
																onChange={(e) => {
																	setAuthor(e.target.value);
																}}
															/>
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Proiecte Sustinute de Partener" title="Proiecte Sustinute de Partener">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{resourcesServices.partnerSupportedProjects.count > 0
									? resourcesServices.partnerSupportedProjects.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															{item.img ? (
																<Image
																	className="rounded"
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	onClick={() =>
																		navigate(INFORMATION_ROUTE + "/" + item.id)
																	}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title className="text-primary">
																		{item.title}
																	</Card.Title>
																</a>
																{item.description
																	? parse(
																			Array.from(item.description)
																				.splice(0, 60)
																				.join("") + "...",
																	  )
																	: null}
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Descriere
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.description}
																value={description}
																onChange={(e) => {
																	setDescription(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Fotografie
															</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
															/>
															<span className="text-primary font-2 py-2 ps-1">Autor</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.author}
																value={author}
																onChange={(e) => {
																	setAuthor(e.target.value);
																}}
															/>
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Initierea Afacerii" title="Initierea Afacerii">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{resourcesServices.startingBusiness.count > 0
									? resourcesServices.startingBusiness.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															{item.img ? (
																<Image
																	className="rounded"
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	onClick={() =>
																		navigate(INFORMATION_ROUTE + "/" + item.id)
																	}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title className="text-primary">
																		{item.title}
																	</Card.Title>
																</a>
																{item.description
																	? parse(
																			Array.from(item.description)
																				.splice(0, 60)
																				.join("") + "...",
																	  )
																	: null}
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Descriere
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.description}
																value={description}
																onChange={(e) => {
																	setDescription(e.target.value);
																}}
															/>
															<span className="text-primary font-2 py-2 ps-1">
																Fotografie
															</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
															/>
															<span className="text-primary font-2 py-2 ps-1">Autor</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.author}
																value={author}
																onChange={(e) => {
																	setAuthor(e.target.value);
																}}
															/>
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
				</Tabs>
			</div>
			<AddInvestmentsModal show={show} onHide={() => setShow(false)} selectedTab={selectedTab} />
		</div>
	);
});

export default InvestmentsAdmin;
