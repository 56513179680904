import React, { useContext, useState, useEffect } from "react";
import Calendar from "../components/Calendar";
import { Image } from "react-bootstrap";
import { fetchOneMayorMessage } from "../http/activitiesAPI";
import { Context } from "..";
import { Spinner } from "react-bootstrap";

const PresidentsMessage = () => {
	const [news, setNews] = useState();

	const { activities } = useContext(Context);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchOneMayorMessage(1)
			.then((data) => {
				activities.setMayorMessage(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<div className="d-flex flex-md-row flex-column justify-content-center px-4">
				{activities.mayorMessage ? (
					<div className="d-flex flex-column col-md-8 px-4">
						<div className="py-4 block-title text-center">
							<span className="text-primary">{activities.mayorMessage.title}</span>
						</div>
						<div className="d-flex flex-column align-items-center justify-content-center">
							<Image
								src={process.env.REACT_APP_API_URL + activities.mayorMessage.img}
								className="rounded pb-5"
							/>
							<span className="text-wrap pb-5">{activities.mayorMessage.description}</span>
						</div>
						<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 px-5">
							{activities.mayorMessage.author ? (
								<span className="border border-primary rounded text-primary my-sm-2 p-2 fw-bold">
									Autor: {activities.mayorMessage.author}
								</span>
							) : null}
							{activities.mayorMessage.date ? (
								<span className="border border-primary rounded text-primary p-2 fw-bold">
									{activities.mayorMessage.date}
								</span>
							) : null}
							<div className="d-flex flex-row align-items-center justify-content-end">
								<i
									className="bi bi-printer text-primary icon-3"
									style={{
										fontWeight: "600 !important",
										WebkitTextStroke: 1,
									}}
								/>
								<div
									className="d-flex rounded ms-3 px-4 bg-primary text-white align-items-center"
									style={{ width: 146 }}
								>
									<i className="bi bi-share icon-2" />
									<span className="ps-4 font-1 fw-bold">Share</span>
								</div>
							</div>
						</div>
					</div>
				) : null}
				<Calendar />
			</div>
		</div>
	);
};

export default PresidentsMessage;
