import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import UserStore from "./store/UserStore";
import CityNewsStore from "./store/CityNewsStore";
import LastNewsStore from "./store/LastNewsStore";
import ResourceServicesStore from "./store/ResourceServicesStore";
import InformationProcessStore from "./store/InformationProcessStore";
import CalendarStore from "./store/CalendarStore";
import ContactsStore from "./store/ContactsStore";
import UsefulStore from "./store/UsefulStore";
import RegulationsStore from "./store/RegulationsStore";
import CouncillorsStore from "./store/CouncillorsStore";
import AdministrationStore from "./store/AdministrationStore";
import EconomyStore from "./store/EconomyStore";
import ReferencesStore from "./store/ReferencesStore";
import ContactInformationStore from "./store/ContactInformationStore";
import CitizensHearingProgramStore from "./store/CitizensHearingProgramStore";
import ActivitiesStore from "./store/ActivitiesStore";

export const Context = createContext(null);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Context.Provider
		value={{
			user: new UserStore(),
			cityNews: new CityNewsStore(),
			lastNews: new LastNewsStore(),
			resourcesServices: new ResourceServicesStore(),
			informationProcess: new InformationProcessStore(),
			usefulLinks: new UsefulStore(),
			calendarEvents: new CalendarStore(),
			contacts: new ContactsStore(),
			regulations: new RegulationsStore(),
			councillors: new CouncillorsStore(),
			administration: new AdministrationStore(),
			economy: new EconomyStore(),
			references: new ReferencesStore(),
			contactInformation: new ContactInformationStore(),
			citizensHearingProgram: new CitizensHearingProgramStore(),
			activities: new ActivitiesStore(),
		}}
	>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</Context.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
