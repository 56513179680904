import { $authHost, $host } from "./index";
import { jwtDecode } from "jwt-decode";

export const createUsefulLinks = async (usefulLinks) => {
	const { data } = await $authHost.post("api/useful_links", usefulLinks);
	return data;
};

export const fetchUsefulLinks = async () => {
	const { data } = await $host.get("api/useful_links");
	return data;
};

export const fetchOneUsefulLinks = async (id) => {
	const { data } = await $host.get("api/useful_links/" + id);
	return data;
};

export const removeOneUsefulLinks = async (id) => {
	const { data } = await $host.post("api/useful_links/" + id);
	return data;
};

export const updateOneUsefulLinks = async (processData, id) => {
	const { data } = await $host.post("api/useful_links/u/" + id, processData);
	return data;
};
