import { $authHost, $host } from "./index";
import { jwtDecode } from "jwt-decode";

// First Tab Start
export const createApplicationForms = async (applicationForms) => {
  const { data } = await $authHost.post(
    "api/application_forms",
    applicationForms
  );
  return data;
};

export const fetchApplicationForms = async () => {
  const { data } = await $host.get("api/application_forms");
  return data;
};

export const fetchOneApplicationForms = async (id) => {
  const { data } = await $host.get("api/application_forms/" + id);
  return data;
};

export const removeOneApplicationForms = async (id) => {
  const { data } = await $host.post("api/application_forms/" + id);
  return data;
};

export const removeOneApplicationFormsFile = async (id) => {
  const { data } = await $host.post("api/application_forms/rD/" + id);
  return data;
};

export const updateOneApplicationForms = async (processData, id) => {
  const { data } = await $host.post(
    "api/application_forms/u/" + id,
    processData
  );
  return data;
};
// Next
export const createPoliciesStrategies = async (policiesStrategies) => {
  const { data } = await $authHost.post(
    "api/policies_strategies",
    policiesStrategies
  );
  return data;
};

export const fetchPoliciesStrategies = async () => {
  const { data } = await $host.get("api/policies_strategies");
  return data;
};

export const fetchOnePoliciesStrategies = async (id) => {
  const { data } = await $host.get("api/policies_strategies/" + id);
  return data;
};

export const removeOnePoliciesStrategies = async (id) => {
  const { data } = await $host.post("api/policies_strategies/" + id);
  return data;
};

export const removeOnePoliciesStrategiesFile = async (id) => {
  const { data } = await $host.post("api/policies_strategies/rD/" + id);
  return data;
};

export const updateOnePoliciesStrategies = async (processData, id) => {
  const { data } = await $host.post(
    "api/policies_strategies/u/" + id,
    processData
  );
  return data;
};
// Next
export const createPublicTransportNetwork = async (publicTransportNetwork) => {
  const { data } = await $authHost.post(
    "api/public_transport_network",
    publicTransportNetwork
  );
  return data;
};

export const fetchPublicTransportNetwork = async () => {
  const { data } = await $host.get("api/public_transport_network");
  return data;
};

export const fetchOnePublicTransportNetwork = async (id) => {
  const { data } = await $host.get("api/public_transport_network/" + id);
  return data;
};

export const removeOnePublicTransportNetwork = async (id) => {
  const { data } = await $host.post("api/public_transport_network/" + id);
  return data;
};

export const removeOnePublicTransportNetworkFile = async (id) => {
  const { data } = await $host.post("api/public_transport_network/rD/" + id);
  return data;
};

export const updateOnePublicTransportNetwork = async (processData, id) => {
  const { data } = await $host.post(
    "api/public_transport_network/u/" + id,
    processData
  );
  return data;
};
// Next
export const createInitiatingPublicPrivatePartnerships = async (
  initiatingPublicPrivatePartnerships
) => {
  const { data } = await $authHost.post(
    "api/initiating_public_private_partnerships",
    initiatingPublicPrivatePartnerships
  );
  return data;
};

export const fetchInitiatingPublicPrivatePartnerships = async () => {
  const { data } = await $host.get(
    "api/initiating_public_private_partnerships"
  );
  return data;
};

export const fetchOneInitiatingPublicPrivatePartnerships = async (id) => {
  const { data } = await $host.get(
    "api/initiating_public_private_partnerships/" + id
  );
  return data;
};

export const removeOneInitiatingPublicPrivatePartnerships = async (id) => {
  const { data } = await $host.post(
    "api/initiating_public_private_partnerships/" + id
  );
  return data;
};

export const removeOneInitiatingPublicPrivatePartnershipsFile = async (id) => {
  const { data } = await $host.post(
    "api/initiating_public_private_partnerships/rD/" + id
  );
  return data;
};

export const updateOneInitiatingPublicPrivatePartnerships = async (
  processData,
  id
) => {
  const { data } = await $host.post(
    "api/initiating_public_private_partnerships/u/" + id,
    processData
  );
  return data;
};

// First Tab End

// Second Tab Start

export const createLocationInNumbers = async (locationInNumbers) => {
  const { data } = await $authHost.post(
    "api/location_in_numbers",
    locationInNumbers
  );
  return data;
};

export const fetchLocationInNumbers = async () => {
  const { data } = await $host.get("api/location_in_numbers");
  return data;
};

export const fetchOneLocationInNumbers = async (id) => {
  const { data } = await $host.get("api/location_in_numbers/" + id);
  return data;
};

export const removeOneLocationInNumbers = async (id) => {
  const { data } = await $host.post("api/location_in_numbers/" + id);
  return data;
};

export const removeOneLocationInNumbersFile = async (id) => {
  const { data } = await $host.post("api/location_in_numbers/rD/" + id);
  return data;
};

export const updateOneLocationInNumbers = async (processData, id) => {
  const { data } = await $host.post(
    "api/location_in_numbers/u/" + id,
    processData
  );
  return data;
};
// Next
export const createSuccessStories = async (successStories) => {
  const { data } = await $authHost.post("api/success_stories", successStories);
  return data;
};

export const fetchSuccessStories = async () => {
  const { data } = await $host.get("api/success_stories");
  return data;
};

export const fetchOneSuccessStories = async (id) => {
  const { data } = await $host.get("api/success_stories/" + id);
  return data;
};

export const removeOneSuccessStories = async (id) => {
  const { data } = await $host.post("api/success_stories/" + id);
  return data;
};

export const removeOneSuccessStoriesFile = async (id) => {
  const { data } = await $host.post("api/success_stories/rD/" + id);
  return data;
};

export const updateOneSuccessStories = async (processData, id) => {
  const { data } = await $host.post("api/success_stories/u/" + id, processData);
  return data;
};
// Next
export const createLocationDevelopmentStrategy = async (
  locationDevelopmentStrategy
) => {
  const { data } = await $authHost.post(
    "api/location_development_strategy",
    locationDevelopmentStrategy
  );
  return data;
};

export const fetchLocationDevelopmentStrategy = async () => {
  const { data } = await $host.get("api/location_development_strategy");
  return data;
};

export const fetchOneLocationDevelopmentStrategy = async (id) => {
  const { data } = await $host.get("api/location_development_strategy/" + id);
  return data;
};

export const removeOneLocationDevelopmentStrategy = async (id) => {
  const { data } = await $host.post("api/location_development_strategy/" + id);
  return data;
};

export const removeOneLocationDevelopmentStrategyFile = async (id) => {
  const { data } = await $host.post(
    "api/location_development_strategy/rD/" + id
  );
  return data;
};

export const updateOneLocationDevelopmentStrategy = async (processData, id) => {
  const { data } = await $host.post(
    "api/location_development_strategy/u/" + id,
    processData
  );
  return data;
};
// Next
export const createKeySectors = async (keySectors) => {
  const { data } = await $authHost.post("api/key_sectors", keySectors);
  return data;
};

export const fetchKeySectors = async () => {
  const { data } = await $host.get("api/key_sectors");
  return data;
};

export const fetchOneKeySectors = async (id) => {
  const { data } = await $host.get("api/key_sectors/" + id);
  return data;
};

export const removeOneKeySectors = async (id) => {
  const { data } = await $host.post("api/key_sectors/" + id);
  return data;
};

export const removeOneKeySectorsFile = async (id) => {
  const { data } = await $host.post("api/key_sectors/rD/" + id);
  return data;
};

export const updateOneKeySectors = async (processData, id) => {
  const { data } = await $host.post("api/key_sectors/u/" + id, processData);
  return data;
};
// Next
export const createPartnerSupportedProjects = async (
  partnerSupportedProjects
) => {
  const { data } = await $authHost.post(
    "api/partner_supported_projects",
    partnerSupportedProjects
  );
  return data;
};

export const fetchPartnerSupportedProjects = async () => {
  const { data } = await $host.get("api/partner_supported_projects");
  return data;
};

export const fetchOnePartnerSupportedProjects = async (id) => {
  const { data } = await $host.get("api/partner_supported_projects/" + id);
  return data;
};

export const removeOnePartnerSupportedProjects = async (id) => {
  const { data } = await $host.post("api/partner_supported_projects/" + id);
  return data;
};

export const removeOnePartnerSupportedProjectsFile = async (id) => {
  const { data } = await $host.post("api/partner_supported_projects/rD/" + id);
  return data;
};

export const updateOnePartnerSupportedProjects = async (processData, id) => {
  const { data } = await $host.post(
    "api/partner_supported_projects/u/" + id,
    processData
  );
  return data;
};
// Next
export const createStartingBusiness = async (startingBusiness) => {
  const { data } = await $authHost.post(
    "api/starting_business",
    startingBusiness
  );
  return data;
};

export const fetchStartingBusiness = async () => {
  const { data } = await $host.get("api/starting_business");
  return data;
};

export const fetchOneStartingBusiness = async (id) => {
  const { data } = await $host.get("api/starting_business/" + id);
  return data;
};

export const removeOneStartingBusiness = async (id) => {
  const { data } = await $host.post("api/starting_business/" + id);
  return data;
};

export const removeOneStartingBusinessFile = async (id) => {
  const { data } = await $host.post("api/starting_business/rD/" + id);
  return data;
};

export const updateOneStartingBusiness = async (processData, id) => {
  const { data } = await $host.post(
    "api/starting_business/u/" + id,
    processData
  );
  return data;
};
// Second Tab End

// Third Tab Start
export const createTouristRoutes = async (touristRoutes) => {
  const { data } = await $authHost.post("api/tourist_routes", touristRoutes);
  return data;
};

export const fetchTouristRoutes = async () => {
  const { data } = await $host.get("api/tourist_routes");
  return data;
};

export const fetchOneTouristRoutes = async (id) => {
  const { data } = await $host.get("api/tourist_routes/" + id);
  return data;
};

export const removeOneTouristRoutes = async (id) => {
  const { data } = await $host.post("api/tourist_routes/" + id);
  return data;
};

export const removeOneTouristRoutesFile = async (id) => {
  const { data } = await $host.post("api/tourist_routes/rD/" + id);
  return data;
};

export const updateOneTouristRoutes = async (processData, id) => {
  const { data } = await $host.post("api/tourist_routes/u/" + id, processData);
  return data;
};
// Next
export const createHotels = async (hotels) => {
  const { data } = await $authHost.post("api/hotels", hotels);
  return data;
};

export const fetchHotels = async () => {
  const { data } = await $host.get("api/hotels");
  return data;
};

export const fetchOneHotels = async (id) => {
  const { data } = await $host.get("api/hotels/" + id);
  return data;
};

export const removeOneHotels = async (id) => {
  const { data } = await $host.post("api/hotels/" + id);
  return data;
};

export const removeOneHotelsFile = async (id) => {
  const { data } = await $host.post("api/hotels/rD/" + id);
  return data;
};

export const updateOneHotels = async (processData, id) => {
  const { data } = await $host.post("api/hotels/u/" + id, processData);
  return data;
};
// Next
export const createMuseums = async (museums) => {
  const { data } = await $authHost.post("api/museums", museums);
  return data;
};

export const fetchMuseums = async () => {
  const { data } = await $host.get("api/museums");
  return data;
};

export const fetchOneMuseums = async (id) => {
  const { data } = await $host.get("api/museums/" + id);
  return data;
};

export const removeOneMuseums = async (id) => {
  const { data } = await $host.post("api/museums/" + id);
  return data;
};

export const removeOneMuseumsFile = async (id) => {
  const { data } = await $host.post("api/museums/rD/" + id);
  return data;
};

export const updateOneMuseums = async (processData, id) => {
  const { data } = await $host.post("api/museums/u/" + id, processData);
  return data;
};
// Next
export const createParks = async (parks) => {
  const { data } = await $authHost.post("api/parks", parks);
  return data;
};

export const fetchParks = async () => {
  const { data } = await $host.get("api/parks");
  return data;
};

export const fetchOneParks = async (id) => {
  const { data } = await $host.get("api/parks/" + id);
  return data;
};

export const removeOneParks = async (id) => {
  const { data } = await $host.post("api/parks/" + id);
  return data;
};

export const removeOneParksFile = async (id) => {
  const { data } = await $host.post("api/parks/rD/" + id);
  return data;
};

export const updateOneParks = async (processData, id) => {
  const { data } = await $host.post("api/parks/u/" + id, processData);
  return data;
};
// Next
export const createTouristActivities = async (touristActivities) => {
  const { data } = await $authHost.post(
    "api/tourist_activities",
    touristActivities
  );
  return data;
};

export const fetchTouristActivities = async () => {
  const { data } = await $host.get("api/tourist_activities");
  return data;
};

export const fetchOneTouristActivities = async (id) => {
  const { data } = await $host.get("api/tourist_activities/" + id);
  return data;
};

export const removeOneTouristActivities = async (id) => {
  const { data } = await $host.post("api/tourist_activities/" + id);
  return data;
};

export const removeOneTouristActivitiesFile = async (id) => {
  const { data } = await $host.post("api/tourist_activities/rD/" + id);
  return data;
};

export const updateOneTouristActivities = async (processData, id) => {
  const { data } = await $host.post(
    "api/tourist_activities/u/" + id,
    processData
  );
  return data;
};
// Next
export const createHistoricBuildings = async (historicBuildings) => {
  const { data } = await $authHost.post(
    "api/historic_buildings",
    historicBuildings
  );
  return data;
};

export const fetchHistoricBuildings = async () => {
  const { data } = await $host.get("api/historic_buildings");
  return data;
};

export const fetchOneHistoricBuildings = async (id) => {
  const { data } = await $host.get("api/historic_buildings/" + id);
  return data;
};

export const removeOneHistoricBuildings = async (id) => {
  const { data } = await $host.post("api/historic_buildings/" + id);
  return data;
};

export const removeOneHistoricBuildingsFile = async (id) => {
  const { data } = await $host.post("api/historic_buildings/rD/" + id);
  return data;
};

export const updateOneHistoricBuildings = async (processData, id) => {
  const { data } = await $host.post(
    "api/historic_buildings/u/" + id,
    processData
  );
  return data;
};
// Next
export const createMonuments = async (monuments) => {
  const { data } = await $authHost.post("api/monuments", monuments);
  return data;
};

export const fetchMonuments = async () => {
  const { data } = await $host.get("api/monuments");
  return data;
};

export const fetchOneMonuments = async (id) => {
  const { data } = await $host.get("api/monuments/" + id);
  return data;
};

export const removeOneMonuments = async (id) => {
  const { data } = await $host.post("api/monuments/" + id);
  return data;
};

export const removeOneMonumentsFile = async (id) => {
  const { data } = await $host.post("api/monuments/rD/" + id);
  return data;
};

export const updateOneMonuments = async (processData, id) => {
  const { data } = await $host.post("api/monuments/u/" + id, processData);
  return data;
};
// Next
export const createNotablePersonalities = async (notablePersonalities) => {
  const { data } = await $authHost.post(
    "api/notable_personalities",
    notablePersonalities
  );
  return data;
};

export const fetchNotablePersonalities = async () => {
  const { data } = await $host.get("api/notable_personalities");
  return data;
};

export const fetchOneNotablePersonalities = async (id) => {
  const { data } = await $host.get("api/notable_personalities/" + id);
  return data;
};

export const removeOneNotablePersonalities = async (id) => {
  const { data } = await $host.post("api/notable_personalities/" + id);
  return data;
};

export const removeOneNotablePersonalitiesFile = async (id) => {
  const { data } = await $host.post("api/notable_personalities/rD/" + id);
  return data;
};

export const updateOneNotablePersonalities = async (processData, id) => {
  const { data } = await $host.post(
    "api/notable_personalities/u/" + id,
    processData
  );
  return data;
};
// Next
export const createChurchesMonasteries = async (churchesMonasteries) => {
  const { data } = await $authHost.post(
    "api/churches_monasteries",
    churchesMonasteries
  );
  return data;
};

export const fetchChurchesMonasteries = async () => {
  const { data } = await $host.get("api/churches_monasteries");
  return data;
};

export const fetchOneChurchesMonasteries = async (id) => {
  const { data } = await $host.get("api/churches_monasteries/" + id);
  return data;
};

export const removeOneChurchesMonasteries = async (id) => {
  const { data } = await $host.post("api/churches_monasteries/" + id);
  return data;
};

export const removeOneChurchesMonasteriesFile = async (id) => {
  const { data } = await $host.post("api/churches_monasteries/rD/" + id);
  return data;
};

export const updateOneChurchesMonasteries = async (processData, id) => {
  const { data } = await $host.post(
    "api/churches_monasteries/u/" + id,
    processData
  );
  return data;
};
// Next
export const createForKids = async (forKids) => {
  const { data } = await $authHost.post("api/for_kids", forKids);
  return data;
};

export const fetchForKids = async () => {
  const { data } = await $host.get("api/for_kids");
  return data;
};

export const fetchOneForKids = async (id) => {
  const { data } = await $host.get("api/for_kids/" + id);
  return data;
};

export const removeOneForKids = async (id) => {
  const { data } = await $host.post("api/for_kids/" + id);
  return data;
};

export const removeOneForKidsFile = async (id) => {
  const { data } = await $host.post("api/for_kids/rD/" + id);
  return data;
};

export const updateOneForKids = async (processData, id) => {
  const { data } = await $host.post("api/for_kids/u/" + id, processData);
  return data;
};

// Third Tab End

// Fourth Tab Start

export const createReports = async (reports) => {
  const { data } = await $authHost.post("api/reports", reports);
  return data;
};

export const fetchReports = async () => {
  const { data } = await $host.get("api/reports");
  return data;
};

export const fetchOneReports = async (id) => {
  const { data } = await $host.get("api/reports/" + id);
  return data;
};

export const removeOneReports = async (id) => {
  const { data } = await $host.post("api/reports/" + id);
  return data;
};

export const removeOneReportsFile = async (id) => {
  const { data } = await $host.post("api/reports/rD/" + id);
  return data;
};

export const updateOneReports = async (processData, id) => {
  const { data } = await $host.post("api/reports/u/" + id, processData);
  return data;
};
// Next
export const createCECEVerbalProcedure = async (cECEVerbalProcedure) => {
  const { data } = await $authHost.post(
    "api/cece_verbal_procedure",
    cECEVerbalProcedure
  );
  return data;
};

export const fetchCECEVerbalProcedure = async () => {
  const { data } = await $host.get("api/cece_verbal_procedure");
  return data;
};

export const fetchOneCECEVerbalProcedure = async (id) => {
  const { data } = await $host.get("api/cece_verbal_procedure/" + id);
  return data;
};

export const removeOneCECEVerbalProcedure = async (id) => {
  const { data } = await $host.post("api/cece_verbal_procedure/" + id);
  return data;
};

export const removeOneCECEVerbalProcedureFile = async (id) => {
  const { data } = await $host.post("api/cece_verbal_procedure/rD/" + id);
  return data;
};

export const updateOneCECEVerbalProcedure = async (processData, id) => {
  const { data } = await $host.post(
    "api/cece_verbal_procedure/u/" + id,
    processData
  );
  return data;
};
// Next
export const createCECEDecisions = async (cECEDecisions) => {
  const { data } = await $authHost.post("api/cece_decisions", cECEDecisions);
  return data;
};

export const fetchCECEDecisions = async () => {
  const { data } = await $host.get("api/cece_decisions");
  return data;
};

export const fetchOneCECEDecisions = async (id) => {
  const { data } = await $host.get("api/cece_decisions/" + id);
  return data;
};

export const removeOneCECEDecisions = async (id) => {
  const { data } = await $host.post("api/cece_decisions/" + id);
  return data;
};

export const removeOneCECEDecisionsFile = async (id) => {
  const { data } = await $host.post("api/cece_decisions/rD/" + id);
  return data;
};

export const updateOneCECEDecisions = async (processData, id) => {
  const { data } = await $host.post("api/cece_decisions/u/" + id, processData);
  return data;
};
// Next
export const createLowerHierarchicalOrgans = async (
  lowerHierarchicalOrgans
) => {
  const { data } = await $authHost.post(
    "api/lower_hierarchical_organs",
    lowerHierarchicalOrgans
  );
  return data;
};

export const fetchLowerHierarchicalOrgans = async () => {
  const { data } = await $host.get("api/lower_hierarchical_organs");
  return data;
};

export const fetchOneLowerHierarchicalOrgans = async (id) => {
  const { data } = await $host.get("api/lower_hierarchical_organs/" + id);
  return data;
};

export const removeOneLowerHierarchicalOrgans = async (id) => {
  const { data } = await $host.post("api/lower_hierarchical_organs/" + id);
  return data;
};

export const removeOneLowerHierarchicalOrgansFile = async (id) => {
  const { data } = await $host.post("api/lower_hierarchical_organs/rD/" + id);
  return data;
};

export const updateOneLowerHierarchicalOrgans = async (processData, id) => {
  const { data } = await $host.post(
    "api/lower_hierarchical_organs/u/" + id,
    processData
  );
  return data;
};
// Next
export const createCECEAnnouncements = async (cECEAnnouncements) => {
  const { data } = await $authHost.post(
    "api/cece_announcements",
    cECEAnnouncements
  );
  return data;
};

export const fetchCECEAnnouncements = async () => {
  const { data } = await $host.get("api/cece_announcements");
  return data;
};

export const fetchOneCECEAnnouncements = async (id) => {
  const { data } = await $host.get("api/cece_announcements/" + id);
  return data;
};

export const removeOneCECEAnnouncements = async (id) => {
  const { data } = await $host.post("api/cece_announcements/" + id);
  return data;
};

export const removeOneCECEAnnouncementsFile = async (id) => {
  const { data } = await $host.post("api/cece_announcements/rD/" + id);
  return data;
};

export const updateOneCECEAnnouncements = async (processData, id) => {
  const { data } = await $host.post(
    "api/cece_announcements/u/" + id,
    processData
  );
  return data;
};
// Next
export const createCECEInformation = async (cECEInformation) => {
  const { data } = await $authHost.post(
    "api/cece_information",
    cECEInformation
  );
  return data;
};

export const fetchCECEInformation = async () => {
  const { data } = await $host.get("api/cece_information");
  return data;
};

export const fetchOneCECEInformation = async (id) => {
  const { data } = await $host.get("api/cece_information/" + id);
  return data;
};

export const removeOneCECEInformation = async (id) => {
  const { data } = await $host.post("api/cece_information/" + id);
  return data;
};

export const removeOneCECEInformationFile = async (id) => {
  const { data } = await $host.post("api/cece_information/rD/" + id);
  return data;
};

export const updateOneCECEInformation = async (processData, id) => {
  const { data } = await $host.post(
    "api/cece_information/u/" + id,
    processData
  );
  return data;
};

// Fourth Tab End
