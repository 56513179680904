import React, { useState } from "react";
import { Form, Modal, Button, Image } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { createLastNews } from "../../../http/NewsAPI";

const AddLastNewsModal = ({ show, onHide }) => {
	const [title, setTitle] = useState("");
	const [article, setArticle] = useState("");

	const AddLastNews = () => {
		const formData = new FormData();
		formData.append("title", title);
		formData.append("article", article);
		createLastNews(formData);
	};

	const modules = {
		toolbar: [
			[{ size: ["small", false, "large", "huge"] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
			["link", "image"],
			[
				{
					color: [
						"#000000",
						"#e60000",
						"#ff9900",
						"#ffff00",
						"#008a00",
						"#0066cc",
						"#9933ff",
						"#ffffff",
						"#facccc",
						"#ffebcc",
						"#ffffcc",
						"#cce8cc",
						"#cce0f5",
						"#ebd6ff",
						"#bbbbbb",
						"#f06666",
						"#ffc266",
						"#ffff66",
						"#66b966",
						"#66a3e0",
						"#c285ff",
						"#888888",
						"#a10000",
						"#b26b00",
						"#b2b200",
						"#006100",
						"#0047b2",
						"#6b24b2",
						"#444444",
						"#5c0000",
						"#663d00",
						"#666600",
						"#003700",
						"#002966",
						"#3d1466",
						"custom-color",
					],
				},
			],
		],
	};

	const formats = [
		"header",
		"height",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"color",
		"bullet",
		"indent",
		"link",
		"image",
		"align",
		"size",
	];

	return (
		<Modal
			show={show}
			onHide={onHide}
			className='add-news-modal'>
			<Modal.Header
				closeButton={onHide}
				className='bg-primary'
				closeVariant='white'>
				<Modal.Title className='text-white'>Adauga Noutate de Ultima Ora</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='d-flex flex-column justify-content-around'>
					<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
					<Form.Control
						aria-label='Title'
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Descrieree</span>
					<ReactQuill
						theme='snow'
						value={article}
						modules={modules}
						formats={formats}
						onChange={setArticle}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={onHide}>
					Close
				</Button>
				<Button
					variant='primary'
					onClick={() => {
						onHide();
						AddLastNews();
					}}>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddLastNewsModal;
