import { useState, useEffect } from "react";
import Calendar from "../../components/Calendar";
import { Image, Carousel } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchOneCityNews } from "../../http/NewsAPI";
import parse from "html-react-parser";

const CityNewsPage = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	useEffect(() => {
		fetchOneCityNews(id)
			.then((data) => {
				setData(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div>
			<div className="d-flex flex-md-row flex-column justify-content-center px-4">
				{data ? (
					<div className="d-flex flex-column col-md-8 px-4">
						<div className="py-4 block-title text-center">
							<span className="text-primary">{data.title}</span>
						</div>
						<div className="d-flex flex-column justify-content-center pb-4">
							{data.img ? (
								<Image
									className="rounded"
									style={{
										maxHeight: "50vh",
										width: "auto",
										aspectRatio: 1,
										objectFit: "contain",
									}}
									src={process.env.REACT_APP_API_URL + data.img}
								/>
							) : null}
							<Carousel className="col-xl-9 col-12" fade>
								{data.city_news_files
									? data.city_news_files.map((item, idx) => (
											<Carousel.Item
												key={idx}
												className="h-100 rounded"
												style={{
													background: `url(${
														process.env.REACT_APP_API_URL + item.img
													})  center center/cover no-repeat`,
													minHeight: "75vh",
													backgroundSize: "cover !important",
													height: "100%",
												}}
											>
												<Carousel.Caption>
													{/* <h3 className='text-start'>{item.title}</h3> */}
													<span
														href="#"
														className="d-flex justify-content-start align-items-center my-3 text-decoration-none text-white ps-4"
													>
														{item.img_description}{" "}
														{/* <i className="bi bi-arrow-right ps-3 icon-2" /> */}
													</span>
												</Carousel.Caption>
											</Carousel.Item>
									  ))
									: null}
							</Carousel>
							<div className="text-justify font-2 pb-2">{parse(data.article)}</div>
						</div>
						<div className="d-flex flex-md-row flex-column align-items-center justify-content-end">
							<div className="d-flex flex-row align-items-center justify-content-end">
								<i
									className="bi bi-printer text-primary icon-3"
									style={{
										fontWeight: "600 !important",
										WebkitTextStroke: 1,
									}}
								/>
								<div
									className="d-flex rounded ms-3 px-4 bg-primary text-white align-items-center"
									style={{ width: 146 }}
								>
									<i className="bi bi-share icon-2" />
									<span className="ps-4 font-1 fw-bold">Share</span>
								</div>
							</div>
						</div>
					</div>
				) : null}
				<Calendar />
			</div>
		</div>
	);
};

export default CityNewsPage;
