import { useContext, useState, useEffect } from "react";
import { Spinner, Card, Accordion, Form, useAccordionButton } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { fetchLastNews, removeOneLastNews, updateOneLastNews } from "../../http/NewsAPI";
import parse from "html-react-parser";
import AddContactsModal from "./Modals/AddContactsModal";
import { fetchContacts } from "../../http/navLinksAPI";

const ContactsAdmin = observer(() => {
	const { contacts } = useContext(Context);
	const [show, setShow] = useState(false);

	const [title, setTitle] = useState("");
	const [article, setArticle] = useState("");

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchContacts()
			.then((data) => {
				contacts.setContacts(data);
			})
			.finally(() => setLoading(false));
	}, [contacts]);

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
				onClick={decoratedOnClick}
			>
				{children}
			</button>
		);
	};

	const removeLastNews = (item) => {
		removeOneLastNews(item.id).then(() => window.location.reload());
	};

	const editLastNews = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("article", article);
		updateOneLastNews(formData, id);
	};

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className="d-flex flex-column bg-white r-corner-radius w-100">
			<div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
				<span>Contacte</span>
				<button
					className="btn btn-primary r-u-corner-radius"
					style={{ height: 70, width: 70 }}
					onClick={() => setShow(true)}
				>
					<i className="bi bi-plus-circle icon-2" />
				</button>
			</div>
			<div className="px-5 mt-4" style={{ maxHeight: 1080, overflow: "auto" }}>
				<Accordion className="pb-4">
					{contacts.contacts.count > 0
						? contacts.contacts.rows.map((item, idx) => (
								<div key={idx} className="shadow-sm border-0 my-2 px-4">
									<Card.Body className="d-flex flex-column">
										<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between">
											<div>
												<div className="text-decoration-none text-dark">
													<Card.Title className="text-primary">{item.title}</Card.Title>
												</div>
												<div className="text-center">
													<span className="border border-primary rounded text-primary p-1">
														{item.date}
													</span>
												</div>
											</div>
											<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
												<CustomToggle
													eventKey={idx}
													onClick={() => {
														useAccordionButton({ idx });
													}}
												>
													<i className="bi bi-pencil-square icon-2 pe-2" />
													<span>Edit</span>
												</CustomToggle>
												<button
													className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
													onClick={() => removeLastNews(item)}
												>
													<i className="bi bi-x-circle icon-2 pe-2" />
													<span>Remove</span>
												</button>
											</div>
										</div>
									</Card.Body>
									<Accordion.Collapse eventKey={idx}>
										<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
											<span className="text-primary  font-2 py-2 ps-1">Denumire</span>
											<Form.Control
												aria-label="Title"
												placeholder={item.title}
												value={title}
												onChange={(e) => {
													setTitle(e.target.value);
												}}
											/>
											<span className="text-primary  font-2 py-2 ps-1">Descriere</span>
											<Form.Control
												aria-label="Description"
												placeholder={item.article}
												value={article}
												onChange={(e) => {
													setArticle(e.target.value);
												}}
											/>
											<button
												className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
												onClick={() => editLastNews(item)}
											>
												<i className="bi bi-check icon-2 pe-2" />
												<span>Confirm</span>
											</button>
										</div>
									</Accordion.Collapse>
								</div>
						  ))
						: null}
				</Accordion>

				{/* <div className='d-flex flex-column justify-content-around'>
					<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
					<Form.Control
						aria-label='Title'
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Descrieree</span>
					<ReactQuill
						theme='snow'
						value={description}
						modules={modules}
						formats={formats}
						onChange={setDescription}
					/>
				</div> */}
			</div>
			<AddContactsModal newsType={"lastNews"} show={show} onHide={() => setShow(false)} />
		</div>
	);
});

export default ContactsAdmin;
