import { useState, useEffect } from "react";
import Calendar from "../components/Calendar";
import { Image } from "react-bootstrap";
import { fetchOneBiography } from "../http/navLinksAPI";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import parse from "html-react-parser";

const Biography = () => {
	const [servicesResp, setServicesResp] = useState();
	const [loading, setLoading] = useState(true);
	const { id } = useParams();

	useEffect(() => {
		fetchOneBiography(id)
			.then((data) => {
				setServicesResp(data);
			})
			.finally(() => setLoading(false));
	}, []);
	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div>
			<div className="d-flex flex-row flex-wrap justify-content-around border-bottom px-md-4 px-2">
				<div className="d-flex flex-column pb-5">
					<div className="block-title text-center py-4">
						<span className="d-flex justify-content-between pt-1">Biografia {servicesResp.role}lui</span>
					</div>
					<div className="d-flex flex-row flex-wrap justify-content-between">
						<div className="d-flex flex-column col-md-4 col-12 pb-5 pb-md-0">
							<Image
								className="col-md-12 col-12 rounded"
								src={process.env.REACT_APP_API_URL + servicesResp.img}
							/>
							<div className="d-flex flex-row align-items-center justify-content-start pt-3 ps-1">
								<i
									className="bi bi-printer text-primary icon-3"
									style={{
										fontWeight: "600 !important",
										WebkitTextStroke: 1,
									}}
								/>
								<div className="d-flex rounded ms-3 px-4 bg-primary text-white align-items-center">
									<i className="bi bi-share icon-2" />
									<span className="ps-4 font-1 fw-bold">Share</span>
								</div>
							</div>
						</div>
						<div className="d-flex flex-column col-md-8 col-12">
							<div className="ps-md-5">
								<div className="bg-primary text-white fw-bold ps-3 rounded my-2">
									INFORMATII PERSONALE
								</div>
								<div>
									<span className="d-flex justify-content-between pt-1">
										<strong className="pe-5">Nume</strong> {servicesResp.last_name}
									</span>
								</div>
								<div>
									<span className="d-flex justify-content-between pt-1">
										<strong className="pe-5">Prenume</strong> {servicesResp.first_name}
									</span>
								</div>
								<div>
									<span className="d-flex justify-content-between pt-1">
										<strong className="pe-5">Data nasterii</strong> {servicesResp.birthdate}
									</span>
								</div>
								<div>
									<span className="d-flex justify-content-between pt-1">
										<strong className="pe-5">Adresa</strong> {servicesResp.address}
										republica Moldova
									</span>
								</div>
								<div>
									<span className="d-flex justify-content-between pt-1">
										<strong className="pe-5">Telefon</strong> {servicesResp.phone}
									</span>
								</div>
								<div>
									<span className="d-flex justify-content-between pt-1">
										<strong className="pe-5">Fax</strong> {servicesResp.fax}
									</span>
								</div>
								<div>
									<span className="d-flex justify-content-between pt-1">
										<strong className="pe-5">Email</strong> {servicesResp.email}
									</span>
								</div>
							</div>
							<div className="ps-md-5 mt-3">
								{servicesResp.biography_studies.length > 0 ? (
									<>
										<div className="bg-primary text-white fw-bold ps-3 rounded my-2">STUDII</div>
										{servicesResp.biography_studies.map((item, idx) => (
											<span key={idx} className="d-flex justify-content-between pt-1">
												<strong className="pe-5">{item.title}</strong> {parse(item.description)}
											</span>
										))}
									</>
								) : null}
							</div>
							<div className="ps-md-5 mt-3">
								{servicesResp.biography_prof_experiences.length > 0 ? (
									<>
										<div className="bg-primary text-white fw-bold ps-3 rounded my-2">
											EXPERIENTA PROFESIONALA
										</div>
										{servicesResp.biography_prof_experiences.map((item, idx) => (
											<span key={idx} className="d-flex justify-content-between pt-1">
												<strong className="pe-5">{item.title}</strong> {parse(item.description)}
											</span>
										))}
									</>
								) : null}
							</div>
							<div className="ps-md-5 mt-3">
								{servicesResp.biography_interships.length > 0 ? (
									<>
										<div className="bg-primary text-white fw-bold ps-3 rounded my-2">
											EXPERIENTA PROFESIONALA
										</div>
										{servicesResp.biography_interships.map((item, idx) => (
											<span key={idx} className="d-flex justify-content-between pt-1">
												<strong className="pe-5">{item.title}</strong> {parse(item.description)}
											</span>
										))}
									</>
								) : null}
							</div>
							<div className="ps-md-5 mt-3">
								{servicesResp.biography_languages.length > 0 ? (
									<>
										<div className="bg-primary text-white fw-bold ps-3 rounded my-2">
											LIMBI STRAINE
										</div>
										{servicesResp.biography_languages.map((item, idx) => (
											<span key={idx} className="d-flex justify-content-between pt-1">
												<strong className="pe-5">{item.title}</strong> {parse(item.description)}
											</span>
										))}
									</>
								) : null}
							</div>
						</div>
					</div>
				</div>
				<Calendar />
			</div>
		</div>
	);
};

export default Biography;
