import React, { useContext } from "react";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import Calendar from "../components/Calendar";

const OnlineMeeting = observer(() => {
  const { cityNews } = useContext(Context);
  return (
    <div className="d-flex flex-column border-bottom">
      <div className="d-flex flex-md-row flex-column justify-content-center px-4">
        <div className="py-4 px-5 col-md-9">
          <div className="block-title pb-4 text-center">
            <span>{cityNews.onlineMeetings[0].title}</span>
          </div>
          <iframe
            title="stream"
            src={cityNews.onlineMeetings[0].video_link}
            className="w-100 rounded shadow"
            style={{ minHeight: "70vh" }}
          />
          <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 py-3 ">
            <span className="border border-primary rounded text-primary my-1 p-2 fw-bold">
              Autor: {cityNews.photos[0].author}
            </span>
            <span className="border border-primary rounded text-primary my-1 p-2 fw-bold">
              {cityNews.photos[0].date}
            </span>
            <div className="d-flex flex-row align-items-center justify-content-end my-1 ps-xl-1">
              <div
                className="d-flex rounded ms-xl-3 px-4 bg-primary text-white align-items-center"
                style={{ width: 146 }}
              >
                <i className="bi bi-share icon-2" />
                <span className="ps-4 font-1 fw-bold">Share</span>
              </div>
            </div>
          </div>
        </div>
        <Calendar />
      </div>
    </div>
  );
});

export default OnlineMeeting;
