import { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import {
	createCECEAnnouncements,
	createCECEDecisions,
	createCECEInformation,
	createCECEVerbalProcedure,
	createLowerHierarchicalOrgans,
	createReports,
} from "../../../http/resourcesServicesAPI";
import { createMayorMessage } from "../../../http/activitiesAPI";
import {
	createBusiness,
	createDecentralizedServices,
	createHistory,
	createMajorBusiness,
	createMunicipalEnterprises,
	createNGovOrganizations,
} from "../../../http/navLinksAPI";

const AddCommunityModal = ({ show, onHide, selectedTab }) => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [author, setAuthor] = useState("");
	const [img, setImg] = useState([null]);

	const [mayorMessage, setMayorMessage] = useState(null);
	const [history, setHistory] = useState(null);
	const [services, setServices] = useState(null);
	const [municipalEnterprises, setMunicipalEnterprises] = useState(null);
	const [nGovOrgs, setNGovOrgs] = useState(null);
	const [business, setBusiness] = useState(null);
	const [majorBusiness, setMajorBusiness] = useState(null);

	// DS

	const [name, setName] = useState("");
	const [fullName, setFullName] = useState("");
	const [phone, setPhone] = useState("");
	const [type, setType] = useState("");
	const [contactPerson, setContactPerson] = useState("");
	const [address, setAddress] = useState("");
	const [article, setArticle] = useState("");

	const addItem = () => {
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		formData.append("author", author);

		// DS
		formData.append("name", name);
		formData.append("full_name", fullName);
		formData.append("phone", phone);
		formData.append("type", type);
		formData.append("address", address);
		formData.append("contact_person", contactPerson);
		formData.append("article", article);

		switch (selectedTab) {
			case "Mesajul Presedintelui":
				createMayorMessage(formData).then(() => window.location.reload());
				break;
			case "Istoria R-ului":
				createHistory(formData).then(() => window.location.reload());
				break;
			case "Serviciile descentralizate":
				createDecentralizedServices(formData).then(() => window.location.reload());
				break;
			case "Întreprinderi municipale":
				createMunicipalEnterprises(formData).then(() => window.location.reload());
				break;
			case "Organizații neguvernamentale":
				createNGovOrganizations(formData).then(() => window.location.reload());
				break;
			case "Afaceri":
				article !== ""
					? createBusiness(formData).then(() => window.location.reload())
					: createMajorBusiness(formData).then(() => window.location.reload());
				break;
		}
	};

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			className='add-news-modal border-primary'>
			<Modal.Header
				closeButton={onHide}
				className='bg-primary'
				closeVariant='white'>
				<Modal.Title className='text-white'>{selectedTab}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{selectedTab === "Mesajul Presedintelui" || selectedTab === "Istoria R-ului" ? (
					<>
						<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
						<Form.Control
							aria-label='Title'
							value={title}
							onChange={(e) => {
								setTitle(e.target.value);
							}}
						/>
						<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
						<Form.Control
							aria-label='Title'
							value={description}
							onChange={(e) => {
								setDescription(e.target.value);
							}}
						/>
						<span className='text-primary  font-2 py-2 ps-1'>Autor</span>
						<Form.Control
							aria-label='Title'
							value={author}
							onChange={(e) => {
								setAuthor(e.target.value);
							}}
						/>
						<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
						<Form.Control
							type={"file"}
							onChange={selectFile}
							aria-label='PageTitle'
							aria-describedby='basic-addon'
						/>
					</>
				) : null}
				{selectedTab === "Întreprinderi municipale" ? (
					<>
						<span className='text-primary  font-2 py-2 ps-1'>Tipul Interprinderii</span>
						<Form.Control
							aria-label='Title'
							value={type}
							onChange={(e) => {
								setType(e.target.value);
							}}
						/>
					</>
				) : null}

				{selectedTab !== "Mesajul Presedintelui" && selectedTab !== "Istoria R-ului" ? (
					<>
						<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
						<Form.Control
							aria-label='Title'
							value={name}
							onChange={(e) => {
								setName(e.target.value);
							}}
						/>
					</>
				) : null}

				{selectedTab === "Serviciile descentralizate" ? (
					<>
						<span className='text-primary  font-2 py-2 ps-1'>Responsabil</span>
						<Form.Control
							aria-label='Title'
							value={fullName}
							onChange={(e) => {
								setFullName(e.target.value);
							}}
						/>
					</>
				) : null}

				{selectedTab === "Organizații neguvernamentale" ? (
					<>
						<span className='text-primary  font-2 py-2 ps-1'>Adresa</span>
						<Form.Control
							aria-label='Title'
							value={address}
							onChange={(e) => {
								setAddress(e.target.value);
							}}
						/>
						<span className='text-primary  font-2 py-2 ps-1'>Persoana de contact</span>
						<Form.Control
							aria-label='Title'
							value={contactPerson}
							onChange={(e) => {
								setContactPerson(e.target.value);
							}}
						/>
					</>
				) : null}

				{selectedTab === "Serviciile descentralizate" || selectedTab === "Organizații neguvernamentale" ? (
					<>
						<span className='text-primary  font-2 py-2 ps-1'>Nr. de Telefon</span>
						<Form.Control
							aria-label='Phone'
							value={phone}
							onChange={(e) => {
								setPhone(e.target.value);
							}}
						/>
					</>
				) : null}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={onHide}>
					Close
				</Button>
				<Button
					variant='primary'
					onClick={() => {
						addItem();
						onHide();
					}}>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddCommunityModal;
