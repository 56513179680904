import React, { useEffect, useState } from "react";
import Calendar from "../components/Calendar";
import { Table } from "react-bootstrap";
import { getCECE } from "../http/ceceAPI";

const CouncillorsList = () => {
	// const { councillors } = useContext(Context);
	const [councillors, setCouncillors] = useState([]);

	useEffect(() => {
		getCECE().then((data) => {
			groupByPartid(data.Consilieri);
		});
	}, []);

	const groupByPartid = async (list) => {
		const groupedCandidati = {};

		list.forEach((candidat, idx) => {
			const partidDenumire = candidat.Partid.Denumire;
			if (!groupedCandidati[partidDenumire]) {
				groupedCandidati[partidDenumire] = {
					Partid: candidat.Partid.Denumire,
					members: [],
				};
			}
			groupedCandidati[partidDenumire].members.push(candidat);
		});

		const sortedGroups = Object.values(groupedCandidati).sort((a, b) => b.members.length - a.members.length);

		return setCouncillors(sortedGroups);
	};

	return (
		<div>
			<div className="d-flex flex-md-row flex-column justify-content-center px-4">
				<div className="d-flex flex-column col-md-8 px-4">
					<div className="py-4 block-title text-center">
						<span className="text-primary">Lista consilierilor Consiliului Raional Edineț</span>
					</div>
					{councillors.map((item, idx) => (
						<div key={idx}>
							<div className="py-4 block-title text-center font-2">
								<span className="text-primary">{item.Partid}</span>
							</div>
							<Table key={"table-" + idx} bordered hover>
								<thead>
									<tr>
										<th className="bg-primary text-white">#</th>
										<th className="bg-primary text-white">Numele, Prenumele</th>
										<th className="bg-primary text-white">Anul Nasterii</th>
									</tr>
								</thead>
								<tbody>
									{item.members.map((sub_item, sub_idx) => (
										<tr key={sub_idx}>
											<td>{sub_idx + 1}</td>
											<td>{sub_item.Candidat.Nume + " " + sub_item.Candidat.Prenume}</td>
											<td>{sub_item.Candidat.AnNastere}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					))}
					<div className="d-flex flex-xl-row flex-column align-items-center justify-content-end w-100">
						<i
							className="bi bi-printer text-primary icon-3 px-4"
							style={{
								fontWeight: "600 !important",
								WebkitTextStroke: 1,
							}}
						/>
						<div
							className="d-flex rounded ms-3 px-4 bg-primary text-white align-items-center"
							style={{ width: 146 }}
						>
							<i className="bi bi-share icon-2" />
							<span className="ps-4 font-1 fw-bold">Share</span>
						</div>
					</div>
				</div>
				<Calendar />
			</div>
		</div>
		// <div className="d-flex flex-xl-row flex-column justify-content-around px-4 border-bottom">
		//   <div className="d-flex flex-column px-xl-5 py-4 col-xl-9 col-12">
		//     <div className="block-title pb-4">
		//       <span>Lista consilierilor Consiliului Raional Edineț</span>
		//     </div>

		//     <div className="d-flex flex-column justify-content-start shadow rounded px-5">
		//       <iframe
		//         id="widget_iframe idCecWidgetConsilieri15136"
		//         className="rounded"
		//         style={{
		//           borderWidth: 0,
		//           width: "100%",
		//           height: 1000,
		//           backgroundColor: "white",
		//           borderColor: "black",
		//           borderRadius: 0,
		//         }}
		//         src="http://serviciidate.cec.md/widgets/consilieri?id=idCecWidgetConsilieri115&HideAlegere=true&Tabs=About"
		//         scrolling="no"
		//       />
		//     </div>
		//   </div>
		//   <Calendar />
		// </div>
	);
};

export default CouncillorsList;
