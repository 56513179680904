import { makeAutoObservable } from "mobx";

export default class ContactsStore {
	constructor() {
		this._contacts = [
			// {
			// 	id: 1,
			// 	department: "Departamentul de Cultură",
			// 	responsible: "Dulgheru Ion",
			// 	location: "str. Mihai Viteazu 56",
			// 	location_map: "www.vk.com",
			// 	phone: ["+373 000 00 000"],
			// 	email: ["hello@createx.com"],
			// 	schedule_days: "Luni-Vineri",
			// 	schedule_time: "09:00 - 18:00",
			// },
			// {
			// 	id: 2,
			// 	department: "Departamentul#0",
			// 	responsible: "Dulgheru Ion",
			// 	location: "str. Mihai Viteazu 56",
			// 	location_map: "www.vk.com",
			// 	phone: ["+373 000 00 000"],
			// 	email: ["hello@createx.com"],
			// 	schedule_days: "Luni-Vineri",
			// 	schedule_time: "09:00 - 18:00",
			// },
			// {
			// 	id: 3,
			// 	department: "Departament#2",
			// 	responsible: "Dulgheru Ion",
			// 	location: "str. Mihai Viteazu 56",
			// 	location_map: "www.vk.com",
			// 	phone: ["+373 000 00 000"],
			// 	email: ["hello@createx.com"],
			// 	schedule_days: "Luni-Vineri",
			// 	schedule_time: "09:00 - 18:00",
			// },
			// {
			// 	id: 4,
			// 	department: "Departamentul#12",
			// 	responsible: "Dulgheru Ion",
			// 	location: "str. Mihai Viteazu 56",
			// 	location_map: "www.vk.com",
			// 	phone: ["+373 000 00 000"],
			// 	email: ["hello@createx.com"],
			// 	schedule_days: "Luni-Vineri",
			// 	schedule_time: "09:00 - 18:00",
			// },
			// {
			// 	id: 5,
			// 	department: "Departamentul#13",
			// 	responsible: "Dulgheru Ion",
			// 	location: "str. Mihai Viteazu 56",
			// 	location_map: "www.vk.com",
			// 	phone: ["+373 000 00 000", "+373 222 00 222"],
			// 	email: ["hello@createx.com", "hello123123@createx.com"],
			// 	schedule_days: "Luni-Vineri",
			// 	schedule_time: "09:00 - 18:00",
			// },
		];
		makeAutoObservable(this);
	}

	setContacts(contacts) {
		this._contacts = contacts;
	}

	get contacts() {
		return this._contacts;
	}
}
