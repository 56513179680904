import { $authHost, $host } from "./index";

// export const createEx = async (ex) => {
// 	const { data } = await $authHost.post("api/ex", ex);
// 	return data;
// };

// export const fetchEX = async () => {
// 	const { data } = await $host.get("api/ex");
// 	return data;
// };

export const createCitizensHearingProgram = async (citizensHearingProgram) => {
	const { data } = await $authHost.post("api/citizens_hearing_program", citizensHearingProgram);
	return data;
};

export const fetchCitizensHearingProgram = async () => {
	const { data } = await $host.get("api/citizens_hearing_program");
	return data;
};

export const fetchOneCitizensHearingProgram = async (id) => {
	const { data } = await $host.get("api/citizens_hearing_program/" + id);
	return data;
};
