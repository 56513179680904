import { useEffect, useState, useContext } from "react";
import { Form, Spinner, Accordion, Card, Image, useAccordionButton, Tabs, Tab } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import {
	DECENTRALIZED_SERVICES_ROUTE,
	MEETINGS_ROUTE,
	PRESIDENTS_MESSAGE_ROUTE,
	REGULATIONS_ROUTE,
	VERBAL_PROCEDURE_ROUTE,
} from "../../utils/consts";
import {
	fetchBiography,
	fetchDraftDecisions,
	fetchMeetings,
	fetchOneOrganization,
	fetchRegulations,
	fetchSpecialCommittees,
	fetchSpecialCommitteesMeets,
	fetchVerbalProcedure,
	removeOneBiography,
	removeOneDraftDecisions,
	removeOneDraftDecisionsFile,
	removeOneMeetings,
	removeOneMeetingsFile,
	removeOneOrganization,
	removeOneRegulations,
	removeOneRegulationsFile,
	removeOneSpecialCommittees,
	removeOneSpecialCommitteesFile,
	removeOneSpecialCommitteesMeets,
	removeOneSpecialCommitteesMeetsFile,
	removeOneVerbalProcedure,
	removeOneVerbalProcedureFile,
	updateOneBiography,
	updateOneDraftDecisions,
	updateOneMeetings,
	updateOneOrganization,
	updateOneRegulations,
	updateOneSpecialCommittees,
	updateOneSpecialCommitteesMeets,
	updateOneVerbalProcedure,
} from "../../http/navLinksAPI";
import AddAdminModal from "./Modals/AddAdminModal";

const AdministrationAdmin = observer(() => {
	const { activities } = useContext(Context);
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const [biography, setBiography] = useState([null]);
	const [organization, setOrganization] = useState([null]);
	const [committees, setCommittees] = useState([null]);
	const [regulations, setRegulations] = useState([null]);
	const [meetings, setMeetings] = useState([null]);
	const [verbalProcedure, setVerbalProcedure] = useState([null]);
	const [specialCommitteesMeets, setSpecialCommitteesMeets] = useState([null]);
	const [draftDecisions, setDraftDecisions] = useState([null]);

	// DS

	const [name, setName] = useState("");
	const [fullName, setFullName] = useState("");
	const [phone, setPhone] = useState("");
	const [type, setType] = useState("");
	const [contactPerson, setContactPerson] = useState("");
	const [address, setAddress] = useState("");
	const [article, setArticle] = useState("");

	const [loading, setLoading] = useState(true);
	const [selectedTab, setSelectedTab] = useState("Conducerea");

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [author, setAuthor] = useState("");
	const [img, setImg] = useState([null]);
	const [files, setFiles] = useState([null]);

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
				onClick={decoratedOnClick}
			>
				{children}
			</button>
		);
	};

	const editItem = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		formData.append("author", author);

		// DS
		formData.append("name", name);
		formData.append("full_name", fullName);
		formData.append("phone", phone);
		formData.append("type", type);
		formData.append("address", address);
		formData.append("contact_person", contactPerson);
		formData.append("article", article);

		for (const file of files) {
			formData.append("files", file);
		}

		switch (selectedTab) {
			case "Conducerea":
				updateOneBiography(formData, id).then(() => window.location.reload());
				break;
			case "Organigrama":
				updateOneOrganization(formData, id).then(() => window.location.reload());
				break;
			case "Comisiile":
				updateOneSpecialCommittees(formData, id).then(() => window.location.reload());
				break;
			case "Regulamente":
				updateOneRegulations(formData, id).then(() => window.location.reload());
				break;
			case "Sedintele":
				updateOneMeetings(formData, id).then(() => window.location.reload());
				break;
			case "Procese Verbale":
				updateOneVerbalProcedure(formData, id).then(() => window.location.reload());
				break;
			case "Sedinte Comisii de Specialitate":
				updateOneSpecialCommitteesMeets(formData, id).then(() => window.location.reload());
				break;
			case "Proiecte de Decizii":
				updateOneDraftDecisions(formData, id).then(() => window.location.reload());
				break;
		}
	};

	const removeItem = (item) => {
		const id = item.id;
		switch (selectedTab) {
			case "Conducerea":
				removeOneBiography(id).then(() => window.location.reload());
				break;
			case "Organigrama":
				removeOneOrganization(id).then(() => window.location.reload());
				break;
			case "Comisiile":
				removeOneSpecialCommittees(id).then(() => window.location.reload());
				break;
			case "Regulamente":
				removeOneRegulations(id).then(() => window.location.reload());
				break;
			case "Sedintele":
				removeOneMeetings(id).then(() => window.location.reload());
				break;
			case "Procese Verbale":
				removeOneVerbalProcedure(id).then(() => window.location.reload());
				break;
			case "Sedinte Comisii de Specialitate":
				removeOneSpecialCommitteesMeets(id).then(() => window.location.reload());
				break;
			case "Proiecte de Decizii":
				removeOneDraftDecisions(id).then(() => window.location.reload());
				break;
		}
	};

	const removeItemFile = (item) => {
		const id = item.id;
		switch (selectedTab) {
			case "Regulamente":
				removeOneRegulationsFile(id).then(() => window.location.reload());
				break;
			case "Sedintele":
				removeOneMeetingsFile(id).then(() => window.location.reload());
				break;
			case "Procese Verbale":
				removeOneVerbalProcedureFile(id).then(() => window.location.reload());
				break;
			case "Sedinte Comisii de Specialitate":
				removeOneSpecialCommitteesMeetsFile(id).then(() => window.location.reload());
				break;
			case "Proiecte de Decizii":
				removeOneDraftDecisionsFile(id).then(() => window.location.reload());
				break;
		}
	};

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	const selectMultipleFiles = (e) => {
		setFiles(e.target.files);
	};

	useEffect(() => {
		fetchBiography().then((data) => {
			setBiography(data);
		});
		fetchOneOrganization(1).then((data) => {
			setOrganization(data);
		});
		fetchSpecialCommittees(1).then((data) => {
			setCommittees(data);
		});
		fetchRegulations().then((data) => setRegulations(data));
		fetchMeetings().then((data) => setMeetings(data));
		fetchVerbalProcedure().then((data) => setVerbalProcedure(data));
		fetchSpecialCommitteesMeets().then((data) => setSpecialCommitteesMeets(data));
		fetchDraftDecisions()
			.then((data) => setDraftDecisions(data))
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return (
			<div className="d-flex align-items-center justify-content-center py-5">
				<Spinner animation={"grow"} />
			</div>
		);
	}

	return (
		<div className="d-flex flex-column bg-white r-corner-radius w-100">
			<div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
				<span>Administrație</span>
				<button
					className="btn btn-primary r-u-corner-radius"
					style={{ height: 70, width: 70 }}
					onClick={() => setShow(true)}
				>
					<i className="bi bi-plus-circle icon-2" />
				</button>
			</div>
			<div className="d-flex flex-column px-2 pt-3">
				<Tabs
					defaultActiveKey="Conducerea"
					id="uncontrolled-tab-example"
					className="d-flex justify-content-center mb-3 text-primary fw-bold"
					onSelect={(e) => setSelectedTab(e)}
				>
					<Tab eventKey="Conducerea" title="Conducerea">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{biography
									? biography.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	href={DECENTRALIZED_SERVICES_ROUTE}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title>{item.name}</Card.Title>
																</a>
																<div>
																	<span>{item.full_name}</span>
																	<span>{item.phone}</span>
																</div>
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary  font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.name}
																value={name}
																onChange={(e) => {
																	setName(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Responsabil
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.full_name}
																value={fullName}
																onChange={(e) => {
																	setFullName(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Nr. de Telefon
															</span>
															<Form.Control
																aria-label="Phone"
																placeholder={item.phone}
																value={phone}
																onChange={(e) => {
																	setPhone(e.target.value);
																}}
															/>
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Organigrama" title="Organigrama">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{organization ? (
									<div className="shadow-sm border-0 my-2 px-4 rounded">
										<div className="d-flex flex-column">
											<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
												<div className="d-flex flex-xl-row flex column align-items-center">
													{organization.img ? (
														<Image
															className="rounded"
															src={process.env.REACT_APP_API_URL + organization.img}
															style={{ maxHeight: "30vh" }}
														/>
													) : null}
													<div className="d-flex flex-column  ps-xl-4">
														<a
															role="button"
															href={PRESIDENTS_MESSAGE_ROUTE}
															className="text-decoration-none text-primary"
														>
															<Card.Title>{organization.title}</Card.Title>
														</a>
														{organization.description
															? parse(
																	Array.from(organization.description)
																		.splice(0, 60)
																		.join("") + "...",
															  )
															: null}
													</div>
												</div>
												<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
													<CustomToggle
														eventKey="organization"
														onClick={() => {
															useAccordionButton("organization");
														}}
													>
														<i className="bi bi-pencil-square icon-2 pe-2" />
														<span>Edit</span>
													</CustomToggle>
													<button
														className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
														onClick={() => removeItem(organization)}
													>
														<i className="bi bi-x-circle icon-2 pe-2" />
														<span>Remove</span>
													</button>
												</div>
											</div>
										</div>
										<Accordion.Collapse eventKey="organization">
											<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
												<div className="d-flex flex-column justify-content-around px-4 py-4">
													<span className="text-primary  font-2 py-2 ps-1">Denumire</span>
													<Form.Control
														aria-label="Title"
														placeholder={organization.title}
														value={title}
														onChange={(e) => {
															setTitle(e.target.value);
														}}
													/>
													<span className="text-primary  font-2 py-2 ps-1">Descriere</span>
													<Form.Control
														aria-label="Title"
														placeholder={organization.description}
														value={description}
														onChange={(e) => {
															setDescription(e.target.value);
														}}
													/>
													<span className="text-primary  font-2 py-2 ps-1">Fotografie</span>
													<Form.Control
														type={"file"}
														onChange={selectFile}
														aria-label="PageTitle"
														aria-describedby="basic-addon"
													/>
												</div>
												<button
													className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
													onClick={() => editItem(organization)}
												>
													<i className="bi bi-check icon-2 pe-2" />
													<span>Confirm</span>
												</button>
											</div>
										</Accordion.Collapse>
									</div>
								) : null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Comisiile" title="Comisiile">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{committees.count > 0
									? committees.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	href={DECENTRALIZED_SERVICES_ROUTE}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title>{item.name}</Card.Title>
																</a>
																<div>
																	<span>{item.full_name}</span>
																	<span>{item.phone}</span>
																</div>
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary  font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.name}
																value={name}
																onChange={(e) => {
																	setName(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Responsabil
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.full_name}
																value={fullName}
																onChange={(e) => {
																	setFullName(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Nr. de Telefon
															</span>
															<Form.Control
																aria-label="Phone"
																placeholder={item.phone}
																value={phone}
																onChange={(e) => {
																	setPhone(e.target.value);
																}}
															/>
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Regulamente" title="Regulamente">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{regulations.count > 0
									? regulations.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															{item.img ? (
																<Image
																	className="rounded"
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	onClick={() =>
																		navigate(REGULATIONS_ROUTE + "/" + item.id)
																	}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title>{item.title}</Card.Title>
																</a>
																{item.description
																	? parse(
																			Array.from(item.description)
																				.splice(0, 60)
																				.join("") + "...",
																	  )
																	: null}
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary  font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Descriere
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.description}
																value={description}
																onChange={(e) => {
																	setDescription(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Fotografie
															</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Documente
															</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
																multiple
															/>
															{item.regulations_files.length > 0
																? item.regulations_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
																		>
																			<div className="d-flex align-items-center">
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a
																					href={
																						process.env.REACT_APP_API_URL +
																						sub_item.doc
																					}
																				>
																					<span
																						className="text-wrap"
																						style={{ textAlign: "justify" }}
																					>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className="btn btn-primary"
																				onClick={() => removeItemFile(sub_item)}
																			>
																				<i className="bi bi-x-circle font-2"></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Sedintele" title="Sedintele">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{meetings.count > 0
									? meetings.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															{item.img ? (
																<Image
																	className="rounded"
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	onClick={() =>
																		navigate(MEETINGS_ROUTE + "/" + item.id)
																	}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title>{item.title}</Card.Title>
																</a>
																{item.description
																	? parse(
																			Array.from(item.description)
																				.splice(0, 60)
																				.join("") + "...",
																	  )
																	: null}
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary  font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Descriere
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.description}
																value={description}
																onChange={(e) => {
																	setDescription(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Fotografie
															</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Documente
															</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
																multiple
															/>
															{item.meetings_files.length > 0
																? item.meetings_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
																		>
																			<div className="d-flex align-items-center">
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a
																					href={
																						process.env.REACT_APP_API_URL +
																						sub_item.doc
																					}
																				>
																					<span
																						className="text-wrap"
																						style={{ textAlign: "justify" }}
																					>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className="btn btn-primary"
																				onClick={() => removeItemFile(sub_item)}
																			>
																				<i className="bi bi-x-circle font-2"></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Procese verbale" title="Procese verbale">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{verbalProcedure.count > 0
									? verbalProcedure.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															{item.img ? (
																<Image
																	className="rounded"
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	onClick={() =>
																		navigate(VERBAL_PROCEDURE_ROUTE + "/" + item.id)
																	}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title>{item.title}</Card.Title>
																</a>
																{item.description
																	? parse(
																			Array.from(item.description)
																				.splice(0, 60)
																				.join("") + "...",
																	  )
																	: null}
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary  font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Descriere
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.description}
																value={description}
																onChange={(e) => {
																	setDescription(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Fotografie
															</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Documente
															</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
																multiple
															/>
															{item.verbal_procedure_files.length > 0
																? item.verbal_procedure_files.map(
																		(sub_item, sub_idx) => (
																			<div
																				key={sub_idx}
																				className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
																			>
																				<div className="d-flex align-items-center">
																					<i
																						className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																					/>
																					<a
																						href={
																							process.env
																								.REACT_APP_API_URL +
																							sub_item.doc
																						}
																					>
																						<span
																							className="text-wrap"
																							style={{
																								textAlign: "justify",
																							}}
																						>
																							{sub_item.doc.split(".")[0]}
																						</span>
																					</a>
																				</div>
																				<button
																					className="btn btn-primary"
																					onClick={() =>
																						removeItemFile(sub_item)
																					}
																				>
																					<i className="bi bi-x-circle font-2"></i>
																				</button>
																			</div>
																		),
																  )
																: null}
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Sedinte Comisii de Specialitate" title="Sedinte Comisii de Specialitate">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{specialCommitteesMeets.count > 0
									? specialCommitteesMeets.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															{item.img ? (
																<Image
																	className="rounded"
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	onClick={() =>
																		navigate(VERBAL_PROCEDURE_ROUTE + "/" + item.id)
																	}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title>{item.title}</Card.Title>
																</a>
																{item.description
																	? parse(
																			Array.from(item.description)
																				.splice(0, 60)
																				.join("") + "...",
																	  )
																	: null}
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary  font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Descriere
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.description}
																value={description}
																onChange={(e) => {
																	setDescription(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Fotografie
															</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Documente
															</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
																multiple
															/>
															{item.special_committees_meets_files.length > 0
																? item.special_committees_meets_files.map(
																		(sub_item, sub_idx) => (
																			<div
																				key={sub_idx}
																				className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
																			>
																				<div className="d-flex align-items-center">
																					<i
																						className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																					/>
																					<a
																						href={
																							process.env
																								.REACT_APP_API_URL +
																							sub_item.doc
																						}
																					>
																						<span
																							className="text-wrap"
																							style={{
																								textAlign: "justify",
																							}}
																						>
																							{sub_item.doc.split(".")[0]}
																						</span>
																					</a>
																				</div>
																				<button
																					className="btn btn-primary"
																					onClick={() =>
																						removeItemFile(sub_item)
																					}
																				>
																					<i className="bi bi-x-circle font-2"></i>
																				</button>
																			</div>
																		),
																  )
																: null}
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab eventKey="Proiecte de Decizii" title="Proiecte de Decizii">
						<div className="d-flex flex-column">
							<Accordion className="pb-4">
								{draftDecisions && draftDecisions.count > 0
									? draftDecisions.rows.map((item, idx) => (
											<div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
												<div className="d-flex flex-column">
													<div className="d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3">
														<div className="d-flex flex-xl-row flex column align-items-center">
															{item.img ? (
																<Image
																	className="rounded"
																	src={process.env.REACT_APP_API_URL + item.img}
																	style={{ maxHeight: "30vh" }}
																/>
															) : null}
															<div className="d-flex flex-column  ps-xl-4">
																<a
																	role="button"
																	onClick={() =>
																		navigate(VERBAL_PROCEDURE_ROUTE + "/" + item.id)
																	}
																	className="text-decoration-none text-primary"
																>
																	<Card.Title>{item.title}</Card.Title>
																</a>
																{item.description
																	? parse(
																			Array.from(item.description)
																				.splice(0, 60)
																				.join("") + "...",
																	  )
																	: null}
															</div>
														</div>
														<div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}
															>
																<i className="bi bi-pencil-square icon-2 pe-2" />
																<span>Edit</span>
															</CustomToggle>
															<button
																className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
																onClick={() => removeItem(item)}
															>
																<i className="bi bi-x-circle icon-2 pe-2" />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
														<div className="d-flex flex-column justify-content-around px-4 py-4">
															<span className="text-primary  font-2 py-2 ps-1">
																Denumire
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Descriere
															</span>
															<Form.Control
																aria-label="Title"
																placeholder={item.description}
																value={description}
																onChange={(e) => {
																	setDescription(e.target.value);
																}}
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Fotografie
															</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
															/>
															<span className="text-primary  font-2 py-2 ps-1">
																Documente
															</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label="PageTitle"
																aria-describedby="basic-addon"
																multiple
															/>
															{item.draft_decisions_files.length > 0
																? item.draft_decisions_files.map(
																		(sub_item, sub_idx) => (
																			<div
																				key={sub_idx}
																				className="d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4"
																			>
																				<div className="d-flex align-items-center">
																					<i
																						className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																					/>
																					<a
																						href={
																							process.env
																								.REACT_APP_API_URL +
																							sub_item.doc
																						}
																					>
																						<span
																							className="text-wrap"
																							style={{
																								textAlign: "justify",
																							}}
																						>
																							{sub_item.doc.split(".")[0]}
																						</span>
																					</a>
																				</div>
																				<button
																					className="btn btn-primary"
																					onClick={() =>
																						removeItemFile(sub_item)
																					}
																				>
																					<i className="bi bi-x-circle font-2"></i>
																				</button>
																			</div>
																		),
																  )
																: null}
														</div>
														<button
															className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
															onClick={() => editItem(item)}
														>
															<i className="bi bi-check icon-2 pe-2" />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					{/* <Tab
						eventKey='Mesajul Presedintelui'
						title='Mesajul Presedintelui'>
						<div className='d-flex flex-column'>
							<Accordion className='pb-4'>
								{mayorMessage ? (
									<div
										key={idx}
										className='shadow-sm border-0 my-2 px-4 rounded'>
										<div className='d-flex flex-column'>
											<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between  p-3'>
												<div className='d-flex flex-xl-row flex column align-items-center'>
													{item.img ? (
														<Image
															className='rounded'
															src={process.env.REACT_APP_API_URL + mayorMessage.img}
															style={{ maxHeight: "30vh" }}
														/>
													) : null}
													<div className='d-flex flex-column  ps-xl-4'>
														<a
															role='button'
															href={PRESIDENTS_MESSAGE_ROUTE}
															className='text-decoration-none text-primary'>
															<Card.Title>{mayorMessage.title}</Card.Title>
														</a>
														{mayorMessage.description
															? parse(Array.from(mayorMessage.description).splice(0, 60).join("") + "...")
															: null}
													</div>
												</div>
												<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
													<CustomToggle
														eventKey={idx}
														onClick={() => {
															useAccordionButton({ idx });
														}}>
														<i className='bi bi-pencil-square icon-2 pe-2' />
														<span>Edit</span>
													</CustomToggle>
													<button
														className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
														onClick={() => removeItem(item)}>
														<i className='bi bi-x-circle icon-2 pe-2' />
														<span>Remove</span>
													</button>
												</div>
											</div>
										</div>
										<Accordion.Collapse eventKey={idx}>
											<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
												<div className='d-flex flex-column justify-content-around px-4 py-4'>
													<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
													<Form.Control
														aria-label='Title'
														placeholder={mayorMessage.title}
														value={title}
														onChange={(e) => {
															setTitle(e.target.value);
														}}
													/>
													<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
													<Form.Control
														aria-label='Title'
														placeholder={mayorMessage.description}
														value={description}
														onChange={(e) => {
															setDescription(e.target.value);
														}}
													/>
													<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
													<Form.Control
														type={"file"}
														onChange={selectFile}
														aria-label='PageTitle'
														aria-describedby='basic-addon'
													/>
												</div>
												<button
													className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
													onClick={() => editItem(mayorMessage)}>
													<i className='bi bi-check icon-2 pe-2' />
													<span>Confirm</span>
												</button>
											</div>
										</Accordion.Collapse>
									</div>
								) : null}
							</Accordion>
						</div>
					</Tab> */}
				</Tabs>
			</div>
			<AddAdminModal show={show} onHide={() => setShow(false)} selectedTab={selectedTab} />
		</div>
	);
});

export default AdministrationAdmin;
